import React, { useState, useEffect } from 'react';
import AudioInput from '../fragment_components/AudioInput';
import parse from 'html-react-parser';
import '../App.css';

function ListIndexFragments(props){

  const [isOpenListFragment, setIsOpenListFragment] = useState(false);

  const {
    selectedLanguagePlatform,
    baseEntryCellsStyling: {
      backgroundColor
    },
    fragmentTemplate: {
      author,
      date,
      mainText,
      selectedKeywords,
      title,
      mediaImg,
      mediaAudio,
      isOnlyText,
      selectedLanguage
    },
    toggleSelectedFragment,
    selectedFragmentIndex,
    index
  } = props;


  let returnOpenFragmentMediaIfAny = () => {
    if(mediaImg !== "" && mediaAudio === ""){
      return (
        // Removing this as the color does not apply
        // style={{backgroundColor: backgroundColor}}
        <div>
          <img 
            src={mediaImg} />
        </div>
      )
    }

    if(mediaAudio !== "" && mediaImg === ""){
      return (
        <AudioInput 
          audioData={mediaAudio}
          audioName={title}
        />
      )
    };


    if(mediaAudio !== "" && mediaImg !== ""){
      return (
        <div>
        <AudioInput 
          audioData={mediaAudio}
          audioName={title}
          backgroundColor={backgroundColor}
        />
         <div style={{backgroundColor: backgroundColor}}>
          <img 
            src={mediaImg} />
        </div>
        </div>
      )
    };


  };

  let renderKeywordsList = () => {

    let selectedKeywordsMaped = selectedKeywords
    .map((ele, index) => {

      const {
        keywordText
      } = ele;

      let keywordTextSplited = keywordText.split(",");

      return `#${ele.keywordText} `
    })

    return selectedKeywordsMaped;

  }

  let renderIfOpen = () => {


    let cellId = `cellOpen_${index}`

       return (
        <section 
          id={cellId}
          className="index_section_list_cells_open">
          <div className='index_section_list_cells_open_inner_1'>
            <p>{renderKeywordsList()}</p>
            {parse(mainText)}
          </div>
          <div className='index_section_list_cells_open_inner_2'>
             {returnOpenFragmentMediaIfAny()}
          </div>
        </section>
      )
  };

  let renderTitleAndKeywords = () => {

    return (
      <div className='title_keywords_close_div'>
        <span>{title}</span>
        {renderKeywordsList()}

      </div>
    )
  };

  let dynamicCopyText = () => {
    if(selectedLanguagePlatform === "fr"){
      return "Texte"
    }else{
      return "Text"
    }
  };


  let generateCopyCells = (copy) => {
    let isText = mainText.length;
    if(isText > 0){
      return `${copy}, ${dynamicCopyText()}`
    }else{
      return copy
    }
  }

  let renderContentType = () => {

    if(isOnlyText){
      return dynamicCopyText();
    }
    if(mediaImg === "" && mediaAudio !== "" ){
      return generateCopyCells("Audio")
    }
    if(mediaImg !== "" && mediaAudio === "" ){
      return generateCopyCells("Image")
    }
    if(mediaImg !== "" && mediaAudio !== "" ){
      return generateCopyCells("Audio, Image")
    }
  }

  let handleMouseClick = () => {

    setIsOpenListFragment(!isOpenListFragment)

    let cellsOpenHtmlCollection = document.getElementsByClassName("index_section_list_cells_open");
    let cellsOpenToArray = [...cellsOpenHtmlCollection];
    cellsOpenToArray.map(ele  => {
      return ele.style.display = "none";
    })

    let selectedCellId =  `cellOpen_${index}`
    let selectedCellIdEle = document.getElementById(selectedCellId);
    selectedCellIdEle.style.display = "flex";


  }

  return (
    <main className='index_section_list_cells_outer'>
    <section 
      onClick={handleMouseClick} 
      className="index_section_list_cells">
      <div className='index_title_keywords'>{renderTitleAndKeywords()}</div>
      <div>{author}</div>
      <div>{renderContentType()}</div>
      <div>{date}</div>
      <div className='uppercase'>{selectedLanguage}</div>
    </section>
      {renderIfOpen()}
    </main>
  )
}

export default ListIndexFragments;