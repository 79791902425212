import React from 'react';
import Favicon from 'react-favicon'
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <div>
  <React.StrictMode>
    <Favicon url='https://res.cloudinary.com/sismographie-cloud/image/upload/v1658438218/design_assets/favicon_ylaecm.png' />
    <Home />
  </React.StrictMode>
  </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
