import React, { useState, useEffect } from 'react';
import '../App.css';
import FiltersKeyword from './FiltersKeyword';

function Filters(props){

  const [filteredKeywords, setfilteredKeywords] = useState([]);

    const {
      keywordsArray,
      keywordsArrayENFR,
      selectedLanguage,
      triggerFilteredFragments,
      closeOverlayedSection,
      filtersPage,
      toggleRandomEntry
    } = props;

    const handleClickKeywords = (keyword) => {
      if(filteredKeywords.includes(keyword)){
        let newArray = filteredKeywords.filter(ele => ele !== keyword);
        setfilteredKeywords(newArray)   
      }else{
        setfilteredKeywords(filteredKeywords => [...filteredKeywords, keyword])
      }
    };

    const clearFilters = () => {
      setfilteredKeywords([]);
    }

    const sendFilteredFragments = () => {
      if(filteredKeywords.length === 0){
        return null;
      };
      let filteredFragmentsData = {
        filteredKeywords
      };
      triggerFilteredFragments(filteredFragmentsData);
    };

    const renderKeywords = () => {
      return keywordsArrayENFR
      .sort((a, b) => a[selectedLanguage].localeCompare(b[selectedLanguage]))
      .map((ele, index) => {
        return (
          <FiltersKeyword 
            selectedLanguage={selectedLanguage}
            keywordObj={ele}
            handleClickKeywords={handleClickKeywords}
            index={index}
            filteredKeywords={filteredKeywords}
          />
        )
      })
    };


    let filtersCTASDynamicStyling = () => {
      if(filteredKeywords.length === 0){
        return {
          opacity: "50%",
          cursor: "default"
        }   
      }else{
        return {
          opacity: "100%",
          cursor: "pointer"
        }  
      }
    };

  
    // could refactor for only dynamicStyles;
    const renderDynamiCTA = () => {
      return (
       <span 
         className='filters_launch_selection'
         style={filtersCTASDynamicStyling()}
         onClick={sendFilteredFragments}>
            {filtersPage[1]}
      </span>   
      );
    };

    const renderDynamicClearFiltersCTA = () => {
      return (
        <span 
          className='filters_delete_selection'
          style={filtersCTASDynamicStyling()}
          onClick={clearFilters}>
          {filtersPage[2]}
        </span>
      )
    }

    return (
      <main>
          <div 
            onClick={closeOverlayedSection}
            className="filters_background">
          </div>
          <section 
            className="filters">
            <div 
              onClick={closeOverlayedSection}
              className='close_cross filters_close' />
            <section className="filters_inner">
                <div>
                  <span className="filter_title">{filtersPage[0]}</span>
                  <div className="filters_list">
                      {renderKeywords()} 
                  </div>           
                </div>
            </section>
              <div className="filter_random_cta">
                <div className='filter_delete_selection_outer'>
                    {renderDynamiCTA()}
                    {renderDynamicClearFiltersCTA()}
                </div>
                <div onClick={toggleRandomEntry}>
                    <span className='filters_show_random'>
                      {filtersPage[3]}
                    </span>
                </div>
              </div>
          </section>
       </main>
    );
}

export default Filters;
