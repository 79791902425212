import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import '../App.css';

function EntryMediaUploader(props){

  const {
    header,
    isBold,
    updateSelectedMedia,
    label,
    instructions,
    id,
    className,
    name,
    accept,
    selectedMediaType,
    deleteFileCTA,
    mediaType,
    media,
    deleteSelectedMedia
  } = props;


  let toggleuploadInfo = () => {

    if(media.length === 0){
      return (
        <div>
        {parse(instructions)}
        </div>
      )
  }
    
      return (
        <div className='entry_form_delete_cta'>
          <div>
            {media.name}
          </div>
          <div 
            className='entry_form_delete_file'
            onClick={deleteSelectedMedia}>
           {deleteFileCTA}
          </div>
        </div>
      )
  };

    return (
      <div 
      className={className}>
      <span>{header}</span>
      <div className='entry_form_uploads'>
        <label className="custom-file-upload">
            <input 
              type="file"
              id={id}
              name={name}
              accept={accept}
              onChange={updateSelectedMedia}
            />
          {label}
        </label>
        <div className='entry_form_upload_instructions'>
          {toggleuploadInfo()} 
      </div>
      </div>
      </div>
    );
}

export default EntryMediaUploader;
