import mockData from "./mock_data_files/mock_data.js";
import React, { useState, useEffect, Children } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import parse from "html-react-parser";
import date from "date-and-time";
import BaseEntryCells from "./fragment_components/BaseEntryCells.js";
import EntryForm from "./entry_components/EntryForm.js";
import Info from "./homepage_components/Info.js";
import Contact from "./homepage_components/Contact.js";
import ListIndex from "./homepage_components/ListIndex.js";
import Ballados from "./ballado_components/Ballados.js";
import Filters from "./filters_components/Filters.js";
import CurvedPathsParent from "./curved_paths_components/CurvedPathsParent.js";

import "./App.css";

const {
  menuArray,
  organicShapes,
  headerInfoArray,
  listCategoryArray,
  colorsArray,
  keywordsArray,
  languageCTA,
  mapEntryText,
  contactPage,
  addCTAImg,
  filtersPage,
  deleteFiltersCTAImg,
  addCTAImgFullBackgroundVersion,
  submissionPage,
  randomQuestionArray,
  ballados,
  cookieSettings,
  projectsAboutText,
  credits,
  sponsorsLogos,
  keywordsArrayENFR,
  entryAcceptanceSetting,
  userAgreementLink,
  resourcesLink
} = mockData;


function Home() {
  const [cmsContent, setCmsContent] = useState(null);
  const [userLanguageCookie, setUserLanguageCookie] = useState(null);
  const [isMap, setisMap] = useState(false);
  const [isAllowedCookies, setIsAllowedCookies] = useState(false);
  const [isKnowMoreCookies, setIsKnowMoreCookies] = useState(false);
  const [isSetCookies, setIsSetCookies] = useState(false);
  const [entryText, setEntryText] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const [mockArrayInView, setMockArrayInView] = useState([]);
  const [initialMockArray, setInitialMockArray] = useState([]);
  const [mockComponentArrayInView, setmockComponentArrayInView] = useState([]);
  const [selectedPageSection, setSelectionPageSection] = useState(null);
  const [numbersBaseEntryCells, setNumbersBaseEntryCells] = useState(0);
  const [openCellId, setOpenCellId] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [entryAcceptance, setIsEntryAcceptance] = useState(false);
  const [isUserAgreement, setIsUserAgreement] = useState(false);


  useEffect(() => {
    const cookies = new Cookies();
    let languageCookie = cookies.get("userLanguage");
    if (languageCookie) {
      setIsSetCookies(true);
      setisMap(true);
    }
    
    const lang = navigator.language;



    axios.get("https://sismographie-back.herokuapp.com/entries")
    .then((res) => {   
      const data = res.data.data;
      let lastEntryIndex = data.length;

      let dataToFragments = data.map((ele, index) => {
        return {
          index,
          toggleCloseAllFragments: toggleCloseAllFragments,
          fragmentTemplate: ele,
          baseEntryCellsStyling: baseEntryCellsStyling(index, false, ele),
          baseEntryContainerStyling: baseEntryContainerStyling(80),
        };
      });
      setInitialMockArray(dataToFragments);
      setMockArrayInView(dataToFragments);
    });
  }, []);



  const showNewFragmentOnMap = async () => {
    setTimeout(() => {
      axios
        .get("https://sismographie-back.herokuapp.com/entries")
        .then((res) => {
          const data = res.data.data;
          let lastEntryIndex = data.length;

          let dataToFragments = data.map((ele, index) => {
            return {
              index,
              toggleCloseAllFragments: toggleCloseAllFragments,
              fragmentTemplate: ele,
              baseEntryCellsStyling: baseEntryCellsStyling(
                index,
                true,
                lastEntryIndex,
                ele
              ),
              baseEntryContainerStyling: baseEntryContainerStyling(60),
            };
          });

          setInitialMockArray(dataToFragments);
          setMockArrayInView(dataToFragments);
        });
    }, 2000);
  };

  useEffect(() => {
    let entriesArrayLength = mockArrayInView.length;

    let mockComponentArrayInView = mockArrayInView.map((ele) => {
      return (
        <BaseEntryCells
          organicShapes={organicShapes}
          data={ele}
          index={ele.index}
          isOpen={ele.index === openCellId}
          setOpenCellId={setOpenCellId}
          entriesArrayLength={entriesArrayLength}
        />
      );
    });
    setmockComponentArrayInView(mockComponentArrayInView);
    setNumbersBaseEntryCells(mockArrayInView.length);
  }, [mockArrayInView, openCellId]);


  useEffect(() => {
    if (isMap) {

      let languageCtas = [...document.getElementsByClassName("language_ctas")];
      languageCtas.map((ele, index) => {
        return (ele.style.fontWeight = "100");
      });

      let selectedLanguageCTA = document.getElementById(selectedLanguage);
      selectedLanguageCTA.style.fontWeight = "800";
    }

    // what is this for?
    if (selectedPageSection === 2 
      || selectedPageSection === 5) {
      return;
    }

    if (selectedPageSection === 3
      || selectedPageSection === 4){
      document.body.style.backgroundColor = "#212533";
    } else {
      document.body.style.backgroundColor = "#E9EAEE";
    }

    let menuSpans = [...document.getElementsByClassName("menu_span")];

    if (selectedPageSection !== null) {
      menuSpans.map((ele, index) => {
        return (ele.style.fontWeight = "100");
      });
      menuSpans.map((ele, index) => {
        return (ele.style.fontStyle = "initial");
      });

      let selectedSpan = document.getElementById(
        `menu_span_${selectedPageSection}`
      );
      selectedSpan.style.fontWeight = "800";
      selectedSpan.style.fontStyle = "italic";
    } else {
      menuSpans.map((ele, index) => {
        return (ele.style.fontWeight = "100");
      });
      menuSpans.map((ele, index) => {
        return (ele.style.fontStyle = "initial");
      });
    }
  }, [isMap, selectedLanguage, selectedPageSection]);

  const openEntryComponent = () => {
    // will optimize: smooth display
    // so that blurry can appear at the same time;
    setisMap(true);
    setOpenCellId(null);
    setSelectionPageSection(5);
  };

  const toggleCloseAllFragments = () => {
    setOpenCellId(null);
  };

  // mainNav component;
  const renderMainNav = () => {
    let menuNav = menuArray[selectedLanguage].map((ele, index) => {
      return (
        <span
          className="menu_span"
          id={`menu_span_${index}`}
          onClick={() => {
            setOpenCellId(null);
            setSelectionPageSection(index);
          }}
          key={index}
        >
          {ele}
        </span>
      );
    });
    // remove if index is displayed
    if (selectedPageSection !== 2) {
      return (
        <nav className="menu">
          {menuNav}
        </nav>
      )
    }
  };

  let renderUserAgreement = () => {

    let userAgreementLinkData = userAgreementLink[selectedLanguage];
    let resourcesLinkData = resourcesLink[selectedLanguage];

    if(selectedPageSection !== null){
      return null;
    };

    return (
      <main className="user_agreement_container">
      <div
        className="user_agreement_cta">
          <span>
            <a href={userAgreementLinkData.cta} target="_blank">
              {userAgreementLinkData.text}
            </a>
          </span>
      </div>
      <div
        className="user_agreement_cta">
          <span>
            <a href={resourcesLinkData.cta} target="_blank">
              {resourcesLinkData.text}
            </a>
          </span>
      </div>
      </main>
    )
  }


  let renderEntryDynamicStyling = () => {
    if (!isAllowedCookies) {
      return "opacity(0.2)";
    } else {
      return "opacity(1)";
    }
  };

  const renderEntryHeader = (className) => {
    if (!isMap) {
      return (
        <div
          style={{ filter: renderEntryDynamicStyling() }}
          className={`${className}_outer`}
        >
          <header className={className}>
            <h1>{headerInfoArray[selectedLanguage].title}</h1>
            <span>{headerInfoArray[selectedLanguage].subtitle}</span>
          </header>
          <nav className="en_fr_cta_entry">{mapCTAs("long")}</nav>
        </div>
      );
    }
  };

  let toggleVisibilityStyling = () => {
    if (selectedPageSection === null) {
      return "visible";
    } else {
      return "hidden";
    }
  };

  const reloadLogoSmall = (className) => {
    if (className === "logo_small") {
      window.location.reload();
    }
  };

  // remove if index is displayed
  const renderMapHeader = (className) => {

    return (
      <div
        style={{ visibility: toggleVisibilityStyling() }}
        className={`${className}_outer`}
      >
        <header
          onClick={() => reloadLogoSmall(className)}
          className={className}
        >
          <h1>{headerInfoArray[selectedLanguage].title}</h1>
          <span>{headerInfoArray[selectedLanguage].subtitle}</span>
        </header>
        <nav className="en_fr_cta_map">{mapCTAs("short")}</nav>
      </div>
    );
  };

  // generating spans in the language toggle component;
  const mapCTAs = (arrayType) => {
    const { short } = languageCTA;

    return languageCTA[arrayType].map((ele, index) => {
      return (
        <span
          id={ele}
          className="language_ctas"
          onClick={() => setLanguageAndCookie(short[index])}
        >
          {ele}
        </span>
      );
    });
  };

  let setLanguageAndCookie = (language) => {
    setLanguage(language);

    const cookies = new Cookies();
    let languageCookie = cookies.get("userLanguage");

    if (languageCookie) {
      return null;
    } else {
      cookies.set("userLanguage", language, { path: "/" });
    }
  };

  // setting the language;
  const setLanguage = (language) => {
    if (!isMap) {
      setEntryText(true);
      setSelectedLanguage(language);
    } else {
      setSelectedLanguage(language);
    }
  };

  const toggleEntryText = () => {
    setEntryText(false);
    setisMap(true);
  };

  // render all sections of the menu
  const renderAdditionalSection = () => {
    switch (selectedPageSection) {
      case 0:
        toggleBlurFragmentsLock(true);


      return (
        <Info
          projectsAboutText={projectsAboutText[selectedLanguage]}
          selectedLanguage={selectedLanguage}
          contactPage={contactPage[selectedLanguage]}
          openEntryComponent={openEntryComponent}
          data={mapEntryText[selectedLanguage]}
          ctas={addCTAImg[selectedLanguage]}
          credits={credits[selectedLanguage]}
          sponsorsLogos={sponsorsLogos[selectedLanguage]}
          closeOverlayedSection={closeOverlayedSection}
          addCTAImgFullBackgroundVersion={
            addCTAImgFullBackgroundVersion[selectedLanguage]
          }
        />
      );
      break;
      case 1:
        toggleBlurFragmentsLock(true);
        return (
          <Filters
            triggerFilteredFragments={triggerFilteredFragments}
            filtersPage={filtersPage[selectedLanguage]}
            closeOverlayedSection={closeOverlayedSection}
            keywordsArray={keywordsArray}
            keywordsArrayENFR={keywordsArrayENFR}
            selectedLanguage={selectedLanguage}
          />
        );
        break;
      case 2:
        toggleBlurFragmentsLock(true);
        return (
          <ListIndex
            selectedLanguage={selectedLanguage}
            listCategoryArray={listCategoryArray[selectedLanguage]}
            listSortingKeys={listCategoryArray.sortingKeys}
            initialMockArray={initialMockArray}
            closeOverlayedSection={closeOverlayedSection}
          />
        );
        break;
  case 3:
    toggleBlurFragmentsLock(true);

    const {
      headline,
      mainContent,
      podcasts
    } = ballados;

    return (
      <Ballados
        mainContent={mainContent[selectedLanguage]}
        headline={headline[selectedLanguage]}
        podcasts={podcasts[selectedLanguage]}
        closeOverlayedSection={closeOverlayedSection}
      />
    )
    break;
    case 4:
        toggleBlurFragmentsLock(true);
        return (
          <Contact
            data={contactPage[selectedLanguage]}
            closeOverlayedSection={closeOverlayedSection}
          />
        );
        break;
      case 5:
        toggleBlurFragmentsLock(true);
        return (
          <EntryForm
            keywordsArrayENFR={keywordsArrayENFR}
            selectedLanguage={selectedLanguage}
            randomQuestionArray={randomQuestionArray[selectedLanguage]}
            closeOverlayedSection={closeOverlayedSection}
            showNewFragmentOnMap={showNewFragmentOnMap}
            keywordsArray={keywordsArray}
            submissionPageData={submissionPage[selectedLanguage]}
          />
        );
        break;
      case null:
        toggleBlurFragmentsLock(false);
        return null;
        break;
      default:
        break;
    }
  };

  const toggleBlurFragmentsLock = (bool) => {
    const fragmentsMaped = [
      ...document.getElementsByClassName("base_entry_cells"),
    ];
    if (bool) {
      document.body.style.overflow = "hidden";
      return fragmentsMaped.map((ele, index) => {
        return (ele.style.filter = "blur(21px)");
      });
    } else {
      document.body.style.overflow = "scroll";
      return fragmentsMaped.map((ele, index) => {
        return (ele.style.filter = "blur(0px)");
      });
    }
  };


  // close section and go back to map;
  // changing "NONE" to null;
  const closeOverlayedSection = () => {
    setSelectionPageSection(null);
  };

  const triggerFilteredFragments = (filteringData) => {
    setSelectionPageSection(null);

    const { filteredKeywords } = filteringData;

    setSelectedFilters(filteredKeywords);

    const filterPerKeywords = (initialMockArray) => {
      if (filteredKeywords.includes(null)) {
        return initialMockArray;
      } else {
        return initialMockArray.filter((ele) => {
          const keywords = ele.fragmentTemplate.selectedKeywords;
          return keywords.some((ele) =>
            filteredKeywords.includes(ele.keywordMain)
          );
        });
      }
    };
    const firstFilter = filterPerKeywords(initialMockArray);

    setMockArrayInView(firstFilter);
  };

  const renderClearFilters = () => {
    return <div>ANNULER FILTRES</div>;
  };

  const renderEntryMap = () => {
    if (!entryText) {
      return (
        <main className="entry_logo">{renderEntryHeader("logo_entry")}</main>
      );
    } else {
      let selectedEntryText = mapEntryText[selectedLanguage];
      const { leftText, rightText } = selectedEntryText;
      return (
        <main className="entry_text">
          <div>
            {renderEntryAcceptancePopup()}
          </div>
          <section className="entry_text_first_section">
            <img src="https://res.cloudinary.com/sismographie-cloud/image/upload/v1646414515/design_assets/empty_1-02_e3izlp.svg" />
            <div className="entry_text_desc">
              <div>
                <p>{parse(leftText.text)}</p>
              </div>
              <div>
                <p>{parse(rightText.text)}</p>
              </div>
            </div>
            <div className="entry_text_ctas">
              <div className="entry_text_cta_section">
                <span onClick={toggleEntryText}>{leftText.cta}</span>
              </div>
              <div className="entry_text_cta_section">
                <span onClick={openEntryComponent}>{rightText.cta}</span>
              </div>
            </div>
          </section>
        </main>
      );
    }
  };

  const renderDynamicMap = () => {
    return (
      <section className="dynamic_map">
        {renderAddContentCTA()}
        {renderDeleteFilters()}
        {mockComponentArrayInView}
      </section>
    );
  };

  const renderAddContentCTA = () => {
    return (
      <img
        style={{ visibility: toggleVisibilityStyling() }}
        onClick={() => {
          setOpenCellId(null);
          setSelectionPageSection(5);
        }}
        className="map_add_cta"
        src={addCTAImg[selectedLanguage]}
      />
    );
  };

  const renderDeleteFilters = () => {
    if (selectedFilters.length > 0) {
      return (
        <img
          onClick={handleDeleteFilters}
          style={{ visibility: toggleVisibilityStyling() }}
          className="map_delete_filters_cta"
          src={deleteFiltersCTAImg[selectedLanguage]}
        />
      );
    }
  };

  const handleDeleteFilters = () => {
    setSelectedFilters([]);
    setMockArrayInView(initialMockArray);
  };

  const allowCookies = () => {
    setIsAllowedCookies(true);
  };

  const knowMoreCookies = () => {
    setIsKnowMoreCookies(true);
  };

  const renderIsKnowMoreCookiesPopup = () => {
    const { cookiesPrompt, cookieDetails } = cookieSettings[selectedLanguage];
    const { cookiesPromptEN, cookieDetailsFR } = cookieSettings["en"];

    if (isKnowMoreCookies) {
      return (
        <div className="know_more_cookies_section">
          <p>{cookiesPrompt}</p>
          <p>{cookieDetails}</p>
          <p>Use of cookies</p>
          <p>This site does not place permanent cookies on hard drives of visitors’ computers, only a temporary cookie to improve the performance of some of the site’s functionality (language, first visit indicator). In continuing to visit the website, you agree to the use of cookies.</p>
          <div className="know_more_cookies_section_cta">
            <p onClick={() => setIsKnowMoreCookies(false)}>OK</p>
          </div>
        </div>
      );
    }
  };

  const renderCookiesPopup = () => {
    if (!isAllowedCookies && !isMap) {
      return (
        <div className="cookies_section">
          <div className="cookies_background_blocker" />
          <img src="https://res.cloudinary.com/sismographie-cloud/image/upload/v1667242492/COOKIES_-_BILINGUE_kkr9l1.svg" />
          <div className="cookies_div">
            <span onClick={allowCookies}>Accepter / Agree</span>
            <span onClick={knowMoreCookies}>En savoir plus / Know more</span>
          </div>
          {renderIsKnowMoreCookiesPopup(cookieSettings)}
        </div>
      );
    }
  };

  let renderCurvedPathsDynamicStyling = () => {
    if(selectedPageSection === null){
      return {
        filter: "blur(0px)"
      }
    }else{
      return {
        filter: "blur(10px)"
      }
    }
  };
  
  const renderCurvedPaths = () => {
    return (
      <CurvedPathsParent 
          toggleCloseAllFragments={toggleCloseAllFragments}
          selectedPageSection={selectedPageSection}
        />
    )
  };
  

  const renderMainmap = () => {
    if(isMap){
      return (
        <main className="map">
          {renderAdditionalSection()}
          {renderMapHeader("logo_small")}
          {renderEntryHeader("logo_entry")}
          {renderMainNav()}
          {renderDynamicMap()}
        </main>
      );
    }
  };

  const renderHomeView = () => {
    if (!isMap) {
      return renderEntryMap();
    } else {
      return (
        <div>
        {renderMainmap()}
        {renderUserAgreement()}
        </div>
      )
    }
  };

  const renderEntryAcceptancePopup = () => {
    if(entryAcceptance){
      return null;
    };
    let entryAcceptanceData = entryAcceptanceSetting[selectedLanguage];

    return (
      <main className="entry_acceptance_main">
          <div className="entry_acceptance_popup">
              <p>
                {parse(entryAcceptanceData.text)}
              </p>
              <span  onClick={() => {
                setIsEntryAcceptance(true);
              }}>
                {entryAcceptanceData.cta}
              </span>
          </div>
          <div>
          </div>
      </main>
    )
  }

  const renderEntryAcceptancePopupAndView = () => {
    return (
      <div>
       {renderHomeView()}
      </div>
    )
  }

  return (
    <main>
      {renderEntryAcceptancePopupAndView()}
      {renderCookiesPopup()}
    </main>
  );
}


export default Home;

// Generate random styling for showing purposes;
const baseEntryCellsStyling = (index, isDrop, lastEntryIndex, ele) => {
  let valTranslate = Math.floor(Math.random() * 50) + 1;
  let valAnimDuraction = Math.floor(Math.random() * 6) + 3;
  let color = colorsArray[Math.floor(Math.random() * colorsArray.length)];


  // Here, get each color for => its section;
  return {
    transform: `rotate(${valTranslate}deg)`,
    backgroundColor: color,
    minHeight: renderHeightWidth(100).height,
    boxShadow: renderBoxShadowLastEntry(
      index,
      isDrop,
      lastEntryIndex,
      color,
      ele
    ),
  };
};

let renderBoxShadowLastEntry = (index, isDrop, lastEntryIndex, color, ele) => {
  if (isDrop) {
    if (index === lastEntryIndex - 1) {
      return `${color} -9px 20px 17px 20px`;
    }
  }
};

// Generate random styling for showing purposes;
const baseEntryContainerStyling = (value) => {
  return {
    left: `${renderLeftTop(value).left}vw`,
    top: `${renderLeftTop(value).top}vh`,
  };
};

// Generate random styling for showing purposes;
const renderHeightWidth = (value) => {
  let valTranslateWidth = Math.floor(Math.random() * value) + 10;
  let valTranslateHeight = Math.floor(Math.random() * value) + 10;
  return {
    width: valTranslateWidth,
    height: valTranslateHeight,
  };
};

const renderLeftTop = (value) => {
let valLeft = Math.floor(Math.random() * value) + 10;
let valTop = Math.floor(Math.random() * value) + 30;
return {
  left: valLeft,
  top: valTop,
};
};

