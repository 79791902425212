import React, { useState, useEffect, Children } from "react";

function CurvedPathsParent(props) {

  const {
    selectedPageSection,
    toggleCloseAllFragments
  } = props;

  let renderCurvedPathsDynamicStyling = () => {
    if(selectedPageSection === null){
      return {
        filter: "blur(0px)"
      }
    }else{
      return {
        filter: "blur(10px)"
      }
    }
  };
  
  const renderCurvedPaths = () => {
    return (
      <div 
        onClick={toggleCloseAllFragments}
        style={renderCurvedPathsDynamicStyling()}
        id="curvedpath_1" className="curvedpaths">
        <svg viewBox="0 0 100 100">
          <path id="MyPath" fill="none" 
            d="M10,90 Q90,90 90,45 Q90,10 50,10 Q10,10 10,40 Q10,70 45,70 Q70,70 75,50" />
          <text>
          <textPath href="#MyPath" className="curvedpath" fill="#bfbfbf">
          « Svp, dessine-moi un mouton…. » disait le petit prince au pilote.  
          <animate 
            attributeName="startOffset" 
            from="100%" 
            to ="0%" 
            begin="0s" 
            dur="20s" 
            repeatCount="indefinite"
          />
          </textPath>
          </text>
          </svg>
      </div>
    ) 
  };
  
  return (
    <main>
      {renderCurvedPaths()}
    </main>
  );
}


export default CurvedPathsParent;
