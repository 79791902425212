import React, { useState, useEffect } from 'react';
import '../App.css';

function EntryKeywords(props){

  const [isSelected, setIsSelected] = useState(false);

  const {
    keywordMain,
    keywordText,
    index,
    handleSelectedKeywords,
    selectedKeywords
  } = props;

  const toggleKeyword = () => {

    if(selectedKeywords.length > 2 
      && !isSelected){
        return null;
    };     
    setIsSelected(!isSelected);
    handleSelectedKeywords(index, {keywordMain, keywordText});
  }

  const keywordDynamicStyling = () => {
    if(isSelected){
      return {
        backgroundColor: "#434343",
        color: "#E9EAEE"
      }
    }else{
      if(selectedKeywords.length > 2){
        return {
          cursor: "default",
        }
      }
      return {
        filter: "invert(0)"
      }
    }
  };

  const keywordDynamicStylingSpan = () => {
    if(selectedKeywords.length > 2 
      && !isSelected){
      return {
        textDecorationLine: "line-through",
      }
    }
  };

  const renderKeywordCross = () => {
    if(isSelected){
      return (
        <span> (x) </span>
      )
    }else{
     return null;
    }
  }

    return (
      <div
        style={keywordDynamicStyling()} 
        onClick={toggleKeyword}
        key={index}>
        <span style={keywordDynamicStylingSpan()}>
          {keywordText}
        </span>
        {renderKeywordCross()}
      </div>
    );
}

export default EntryKeywords;
