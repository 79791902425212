import React, { useRef, useState, useEffect } from "react";

function AudioInput(props) {

  const {
    index,
    audioData,
    audioTitle,
    audioName,
    podcastDuration,
    inputClassName,
    isPAudioTriggered,
    activePodcastIndex,
    handleActivePodcastSelect
  } = props;

  const audioPlayer = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isVisibleAudio, setIsVisibleAudio] = useState(false);
  const [isAudioActive, setIsAudioActive] = useState(false);

  useEffect(() => {
    if(index !== activePodcastIndex){
      setIsAudioActive(false);
      setIsVisibleAudio(false);
      // remove this audio input;
    } 
  }, [activePodcastIndex]);

  useEffect(() => {
    if(isAudioActive){
      audioPlayer.current.play();
    }else{
      audioPlayer.current.pause();
    }
  }, [isAudioActive])


  let buttonDynamicClassName = () => {
    if(isAudioActive){
      return 'audio_button paused';
    }else{
      return 'audio_button';
    }  
  }

  const onPlaying = () => {
    setCurrentTime(audioPlayer.current.currentTime);
    setDuration(audioPlayer.current.duration);
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100
    );
  };
 

  let dynamicStylingAudioInput = () => {
    if(isAudioActive){
      return "visible"
    }else{
      return "hidden"
    }
  };

   let format = (time) => {   
    let hrs = ~~(time / 3600);
    let mins = ~~((time % 3600) / 60);
    let secs = ~~time % 60;
    let ret = "";
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

  let returnAudioCurrentTime = () => {
    return (
      <div>{format(currentTime)}</div>
    )
  };

  const returnAudioDuration = () => {
    return (
      <div>{podcastDuration}</div>
    )
  };

  const renderAudioInput = () => {
      return (
        <div>
              <audio
              src={audioData}
              ref={audioPlayer}
              id={index}
              onTimeUpdate={onPlaying}>
              Your browser does not support the
              <code>audio</code> element.
            </audio>
            <input
              className={inputClassName}
              type="range"
              min="0"
              max="100"
              step="1"
              value={seekValue}
              onChange={(e) => {
                const seekto = audioPlayer.current.duration * (+e.target.value / 100);
                audioPlayer.current.currentTime = seekto;
                setSeekValue(e.target.value);
              }}
            />
            <div className="audio_input_duration">
              {returnAudioCurrentTime()}
              {returnAudioDuration()}
            </div>
        </div>
      )  
  };

  const handleButton = () => {
    setIsAudioActive(!isAudioActive);
  }

  return (
    <div>
      <div 
        onClick={() => handleActivePodcastSelect(index)}
        className="audio_first_layer">
        <button 
          className={buttonDynamicClassName()}
          onClick={handleButton}
        />
        <div className="audio_input_name_title">
          <div><span>{audioName}</span></div>
          <div><span>{audioTitle}</span></div>
        </div>
      </div>
      {renderAudioInput()}
    </div>
  );
}

export default AudioInput;
