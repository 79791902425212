import React, { useState, useEffect } from "react";
import "../App.css";

// At some point: merge with the other keywords component;

function FiltersKeywords(props) {
  const {
    handleClickKeywords,
    filteredKeywords,
    keywordsArrayENFR,
    selectedLanguage,
    index,
    keywordObj: { main, en, fr },
  } = props;

  let keyword = main;

  const toggleKeyword = () => {
    handleClickKeywords(keyword);
  };

  const keywordDynamicStyling = () => {
    if (filteredKeywords.includes(keyword)) {
      return {
        backgroundColor: "#434343",
        color: "#E9EAF5",
      };
    } else {
      return {
        backgroundColor: "transparent",
        color: "#434343",
      };
    }
  };

  const renderUpdateFiltersCross = () => {
    if (filteredKeywords.includes(keyword)) {
      return " (x) ";
    } else {
      return null;
    }
  };

  let renderDynamicStylingSpans = () => {
    if (filteredKeywords.includes(keyword)) {
      return {
        color: "#E9EAF5",
      }
    }else{
      return {
        color: "#434343",
      }
    }
  }

  const returnKeywordText = () => {
    if (selectedLanguage === "fr") {
      return (
        <div 
          style={renderDynamicStylingSpans()}
          className="keyword_container">
            <span className="bold" style={renderDynamicStylingSpans()}>
              {fr}
              </span>/
                <span style={renderDynamicStylingSpans()}>
                {en}
            </span>
        </div>
      )
    } else {
      return (
        <div 
          style={renderDynamicStylingSpans()}
          className="keyword_container">
            <span className="bold" style={renderDynamicStylingSpans()}>
              {en}
              </span>/
                <span style={renderDynamicStylingSpans()}>
                {fr}
            </span>
        </div>
      )
    }
  };

  return (
    <div>
      <span style={keywordDynamicStyling()} onClick={toggleKeyword}>
        {returnKeywordText()}
        {renderUpdateFiltersCross()}
      </span>
    </div>
  );
}

export default FiltersKeywords;
