import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable'; // The default
import { ReactSVG } from 'react-svg'
import parse from 'html-react-parser';
import '../App.css';
import AudioInput from './AudioInput';


function BaseEntryCells(props){

  const {
    data: {
      baseEntryContainerStyling,
      fragmentTemplate,
      index,
      toggleCloseAllFragments,
    },
    isOpen,
    setOpenCellId,
    entriesArrayLength,
    organicShapes,
  } = props

  const {
    title,
    date,
    author,
    mainText,
    isOnlyText,
    media,
    mediaAudio,
    mediaImg,
    selectedKeywords
  } = fragmentTemplate;

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [selectedShape, setSelectedShapeCategory] = useState(null);
  const [selectedImg, setSelectedImg] = useState("")
  
  useEffect(()=> {
  const {
    mediaAudio,
    mediaImg
  } = fragmentTemplate;

    let shapes = ["shape1", "shape2", "shape3", "shape4"]
    let selectedShape = shapes[Math.floor(Math.random()*shapes.length)];
    setSelectedShapeCategory(selectedShape)
    renderRandomMediaTypeImg(selectedShape)

    // if(mediaAudio !== "" && mediaImg === ""){
    // return renderRandomMediaTypeImg("shape1");
    // }
    // if(mediaImg !== "" && mediaAudio === ""){
    //   return renderRandomMediaTypeImg("shape2");
    // }
    // if(mediaAudio !== "" && mediaImg !== ""){
    //   return renderRandomMediaTypeImg("shape3");
    // }
    // if(mediaImg === "" && mediaAudio === ""){
    //   return renderRandomMediaTypeImg("shape4");
    // }

    // here, add a random between shapes;
    

  }, []);

   const renderRandomMediaTypeImg = (shapeType) => {
    let selectedShapeTypeArray = organicShapes[shapeType];
    let randomNumber = Math.floor(Math.random() * 3) + 0
    setSelectedImg(selectedShapeTypeArray[randomNumber])
  }

  const renderBaseEntryCellHeadline = (backgroundColor) => {
    return (
      <section className="base_entry_headline_container">
          <h1 style={{color: renderColorMediaTypes()}}>{title}</h1>
          <h2 style={{color: renderColorMediaTypes()}}>
            {renderKeyWordsList()}
          </h2>
          <span>{author}</span>
          <span>{date}</span>
      </section>
    )
  };

  const renderBaseEntryCellBody = () => {
    if(isOnlyText){
      return (
        <section className="base_entry_body_container is_only">
          <div>
           {parse(mainText)}
          </div>
        </section>
      )
    }else{
      return (
        <section className="base_entry_body_container text_media">
          <div className='text_media_divs'>
            {parse(mainText)}
          </div>
          <div className='text_media_divs'>
              {generateMediaContent(media)}
          </div>
        </section>
      )
    };
  };

  const generateMediaContent = (media) => {
    const {
      mediaType,
    } = media;
    
    if(mediaAudio !== "" && mediaImg === ""){
      return (
        <section>
            <AudioInput 
              backgroundColor={renderColorMediaTypes()}
              audioData={mediaAudio}
              audioName={title}
            />
        </section>
      )
    }

    if(mediaImg !== "" && mediaAudio === ""){
      return (
        <section 
          className="base_entry_img"
          style={{backgroundColor: renderColorMediaTypes()}}>
          <img src={mediaImg} />
        </section>
      )
    }

    if(mediaAudio !== "" && mediaImg !== ""){
      return (
        <section>
            <AudioInput 
              backgroundColor={renderColorMediaTypes()}
              audioData={mediaAudio}
              audioName={title}
            />

        <section 
          className="base_entry_img media_img_media_audio"
          style={{backgroundColor: renderColorMediaTypes()}}>
          <img src={mediaImg} />
        </section>
        </section>
      )
    }
  };

  const handleCellOpen = () => {
    setOpenCellId(index);
  };

  const handleCellEnter = () => {
    setIsMouseOver(true)
  };

  const handeCellLeave = () => {
    setIsMouseOver(false)
  }

  const closeAllFragments = () => {
    toggleCloseAllFragments();
  };

  const toggleIsCellVisible = () => {
    if(isOpen){
      return "visible"
    }else{
      return "hidden"
    }
  };

  const renderEntryCellsContentOnClick = () => {

    let stylingEntryCell = {
      border: `1px solid ${renderColorMediaTypes()}`,
      borderRadius: "10px",
      display: "block",
      visibility: toggleIsCellVisible()
    };

    return (
      <div>
        <section 
          id={index}
          style={stylingEntryCell}
          className="entry_content">
          {renderBaseEntryCellHeadline(renderColorMediaTypes())}
          {renderBaseEntryCellBody(renderColorMediaTypes())}
        </section>
        <div 
          onClick={closeAllFragments}
          className="entry_content_blocker"/>
      </div>
    )

  };

  const renderKeyWordsList = () => {
    return selectedKeywords
    .map((ele, index) => {
      const {
        keywordText,
        keywordMain
      } = ele;
      return ` #${keywordText} `
    })
  };


  const renderEntryCellsContentOnMouseOver = () => {

    const styles = {
      // color: renderColorMediaTypes(),
      color: "black",
      display: "none",
    }

    if (isMouseOver) {
      styles.display = "block";
    }

    return (
      <div 
        style={styles}
        className='entry_content_hover'>
        <h1>{title}</h1>
        <span>{renderKeyWordsList()}</span>
        <h1 className='base_entry_author'>{author}</h1>
        <h1>{date}</h1>
      </div>
    )
  };

  const renderColorMediaTypes = () => { 
      switch (selectedShape) {
        case "shape1":
          return "#EAC6F5"
          break;
        case "shape2":
          return "#6894AF"
          break;
        case "shape3":
          return "#7A3F95"
          break;
        case "shape4":
          return "#AC390F"
          break;
        default:
          break;
      }

      // if(mediaAudio !== "" && mediaImg === ""){
      //   return " #EAC6F5"
      // }
      // if(mediaImg !== "" && mediaAudio === ""){
      //   return " #6894AF"
      // }
      // if(mediaAudio !== "" && mediaImg !== ""){
      //   return " #7A3F95"
      // }
      // if(mediaImg === "" && mediaAudio === ""){
      //   return "#AC390F"
      // }
  };



  let id = `svg_${index}`;

  if(!selectedImg){
    return null;
  }

  return (
    <section 
      style={baseEntryContainerStyling}
      className="base_entry_container">

       <img
          onMouseEnter={handleCellEnter}
          onMouseLeave={handeCellLeave}
          onClick={handleCellOpen}
          className="base_entry_cells"
          src={selectedImg}
      />  

    {renderEntryCellsContentOnClick()}
    {renderEntryCellsContentOnMouseOver()}
  </section>
  )
};


export default BaseEntryCells;


