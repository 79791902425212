import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import Markdown from 'marked-react';
import "../App.css";

function Info(props) {
  const {
    closeOverlayedSection,
    projectsAboutText,
    selectedLanguage,    
    openEntryComponent,
    addCTAImgFullBackgroundVersion,
    data: {
      leftText,
      rightText
    },
    credits,
    contactPage,
    sponsorsLogos,
  } = props;


  const returnProjectsAboutText = () => {

    return projectsAboutText.map((ele, index) => {   

      const { headline, text } = ele
      return (
        <div className="second_info_div">
          <p className="bold">{headline}</p>
          <p>
            {parse(text)}
          </p>
        </div>
      );
    });
  };

  const returnProjectCredits = () => {
    return (
      <div className="credits_text_container">
        {parse(credits)}
      </div>
    );
  };

  const returnProjectLogos = () => {
    let sponsorsLogosDivs = sponsorsLogos.map((ele, index) => {
      return (
        <div className="project_logos">
          <img src={ele} />
        </div>
      );
    });
    return <div className="sponsors_logo_main">{sponsorsLogosDivs}</div>;
  };

  const returnAcknowledgmentTerritory = () => {
    const {acknowledgmentText} = contactPage;
    return (
      <div className="bold">
         {acknowledgmentText}
      </div>
    )
  };

  return (
    <main className="info_section">
      <div onClick={closeOverlayedSection} className="info_background" />

      <nav>
        <img src="https://res.cloudinary.com/sismographie-cloud/image/upload/v1656419743/design_assets/chevron_zfnyw7.svg" />
      </nav>

      <section>
        <img
          className="info_shape_bottom"
          src="https://res.cloudinary.com/sismographie-cloud/image/upload/v1646488531/design_assets/shape_2-01_e28fw1.svg"
        />

        <div className="first_info">
          <div className="info_section_bloc_left">
            <p>{parse(leftText.text)}</p>
            <div className="info_section_add">
              <img
                onClick={openEntryComponent}
                src={addCTAImgFullBackgroundVersion}
              />
            </div>
          </div>
          <div className="info_section_bloc_right">
            <img src="https://res.cloudinary.com/sismographie-cloud/image/upload/v1646414515/design_assets/empty_1-02_e3izlp.svg" />
            <p>{parse(rightText.text)}</p>
          </div>
        </div>
        <div className="second_info">
          {returnProjectsAboutText()}
          {returnProjectCredits()}
          {returnProjectLogos()}
          {returnAcknowledgmentTerritory()}
        </div>
      </section>
    </main>
  );
}

export default Info;
