import React, { useState, useEffect } from 'react';
import Podcast from "./Podcast"
import parse from 'html-react-parser';
import '../App.css';

function Ballados(props){

  const {
    headline,
    mainContent,
    podcasts,
    closeOverlayedSection,
  } = props;

  const [selectedHoveredPodcast, setSelectedHoveredPodcast] = useState(null);
  const [activePodcastIndex, setActivePodcastIndex] = useState(null);

  const displayHoveredPodcastDesc = (selectedPodcastIndex) => {
       setSelectedHoveredPodcast(podcasts[selectedPodcastIndex]);
  }

  const handleActivePodcastSelect = (clickedPodcastIndex) => {
    if(clickedPodcastIndex !== activePodcastIndex){
      setActivePodcastIndex(clickedPodcastIndex)
    }
  };

   const renderPodcasts = () => {
     return podcasts
     .map((ele, index) => {
       return (
         <Podcast 
          activePodcastIndex={activePodcastIndex}
          displayHoveredPodcastDesc={displayHoveredPodcastDesc}
          handleActivePodcastSelect={handleActivePodcastSelect}
          podcastData={ele}
          index={index}
        />
       )
     })
   };

   const renderSelectedPodcastText = () => {
     if(selectedHoveredPodcast){
      let {podcast_text} = selectedHoveredPodcast;
      return (
        <p>
          {parse(podcast_text)}
        </p>
      )
     }
   }

    return (
      <main className="ballado_section">

        <div 
        onClick={closeOverlayedSection}
        className='ballado_background' />

        <div 
          onClick={closeOverlayedSection}
          class="close_cross close_ballados" 
        />
        
        <section className="ballado_main">
     
            <div className='ballado_main_inner ballado_left'>
              <div className='b_l_1'>
                <div className='ballado_img_block'>
                  <img src={headline.img} />
                  <label>{headline.img_credit}</label>
                </div>
              </div>
              <div className='b_l_2'>
                <p>
                  {parse(mainContent)}
                </p>
                <p>
                  {headline.credits}
                </p>
                <h2>{parse(headline.subpodcast_title)}</h2>
              </div>
            </div>
            <div className='ballado_main_inner ballado_right'>
              <div className='b_r_1'>
                {renderSelectedPodcastText()}
              </div>
              <div className='b_r_2'>
                {renderPodcasts()}
              </div>
            </div>
        </section>
      </main>
    );

}

export default Ballados;
