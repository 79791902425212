import React, { useState, useEffect } from 'react';
import AudioInput from '../fragment_components/AudioInput';
import ListIndexFragments from './ListIndexFragments';
import parse from 'html-react-parser';
import '../App.css';


function ListIndex(props){

  const {
    selectedLanguage,
    closeOverlayedSection,
    selectedLanguagePlatform,
    initialMockArray,
    listCategoryArray: {
      category,
      types
    },
    listSortingKeys,
  } = props;


  const [initialListInView, setInitialListInView] = useState([]);
  const [listInView, setListInView] = useState([]);
  const [sortingKey, setSortingKey] = useState(null);
  const [selectedFragmentIndex, setselectedFragmentIndex] = useState(null);

  let toggleSelectedFragment = (index) => {
    alert(index)
    setselectedFragmentIndex(index)
  }

  useEffect(() => {
    renderInitialListInView();
  }, [])

  let renderInitialListInView = () => {
   
    const listSortedDatesMaped = initialMockArray
    .sort(function(a, b){
      let aa = a.fragmentTemplate.date.split('/').reverse().join();
      let bb = b.fragmentTemplate.date.split('/').reverse().join();
      return bb < aa ? -1 : (bb > aa ? 1 : 0);

    })
    .map((ele, index) => {
      return (
        <ListIndexFragments
          toggleSelectedFragment={toggleSelectedFragment}
          selectedLanguagePlatform={selectedLanguage}
          selectedFragmentIndex={selectedFragmentIndex}
          {...ele}
        />
      )
    })

    setInitialListInView(listSortedDatesMaped)
    setListInView(listSortedDatesMaped);  
  };

  useEffect(() => {
    if(sortingKey !== null){
      sortListInView(sortingKey)
    }
  }, [sortingKey])


  let sortListInView = (sortingKey) => {

    if(sortingKey === "date"){
      return setListInView(initialListInView);  
    }

    if(sortingKey === "mediaType"){

      let text = [];
      let imgText = [];
      let audioText = [];
      let audioImg = [];
      let audio = [];
      let imgTextAudio = [];


      let runMediaClassifier = () => {

        let initialMockArraySorted = initialMockArray
        .map((ele, index) => {
          const {
            isOnlyText,
            mainText,
            mediaAudio,
            mediaImg
          } = ele.fragmentTemplate; 
  
          // this needs to be optimized;
          if(isOnlyText === true){
            return text.push(ele)
          }  
          if(mainText !== "" && mediaImg !== ""){
            return imgText.push(ele)
          }    
          if(mainText !== "" && mediaAudio !== ""){
            return audioText.push(ele)
          } 
          if(mainText === "" && mediaAudio !== "" && mediaImg !== ""){
            return audioImg.push(ele)
          }    
          if(mainText === "" && mediaAudio !== ""){
            return audio.push(ele)
          }  
          if(mainText !== "" && mediaAudio === ""){
            return audio.push(ele)
          } 
          if(mainText !== "" && mediaAudio !== "" && mediaImg !== ""){
            return imgTextAudio.push(ele)
          } 

        })

        let arrayMixed = [
          ...text, 
          ...imgText,
          ...audioText,
          ...audioImg,
          ...audio,
          ...imgTextAudio
        ];

        return arrayMixed;

      }

      const newArraySortedMedia = runMediaClassifier()
      .map((ele, index) => {
        return (
          <ListIndexFragments 
            toggleSelectedFragment={toggleSelectedFragment}
            selectedLanguagePlatform={selectedLanguage}
            selectedFragmentIndex={selectedFragmentIndex}
            {...ele}
          />
        )
      })

      return setListInView(newArraySortedMedia);  
    }

    const sortByObjectName = (sortingKey) => { 
      return initialMockArray.sort(function(a, b) {
        return a.fragmentTemplate[sortingKey].localeCompare(b.fragmentTemplate[sortingKey]);
      });
    };

    const newArraySortedMap = sortByObjectName(sortingKey)
    .map((ele, index) => {
      return (
        <ListIndexFragments 
          toggleSelectedFragment={toggleSelectedFragment}
          selectedLanguagePlatform={selectedLanguage}
          selectedFragmentIndex={selectedFragmentIndex}
          {...ele}
        />
      )
    });

    setListInView(newArraySortedMap);  

  };


  const renderListCategoryMaped = () => {
    return category
    .map((ele, index) => {
      return (
        <span 
          onClick={() => {setSortingKey(listSortingKeys[index])}}
          key={index}>{ele}
        </span>
      )
    })
   return renderListCategoryMaped;
  }

    return (
      <main
        className="index_section">
        <section className="index_section_main">
           <header>
              {renderListCategoryMaped()}
              <div>
                <div 
                onClick={closeOverlayedSection}
                className='close_cross list_close' />
              </div>
           </header>
           <main className='list_index_main'>
              {listInView}
          </main>
        </section>  
      </main>

    );
}


export default ListIndex;