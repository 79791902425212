import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import "../App.css";

function EntryFormPopups(props) {
  const {
    randomQuestionCTA,
    popupcloseOverlayedSection,
    loadingPopupData,
    mediaAudio,
    mediaImg,
    doubleCheckPopupData,
    confirmClosePopupData,
    randomQuestions,
    entryFormPopupToggle,
    publishTheEntry,
    toggleRandomQuestionsIndex,
    randomQuestionIndex,
    type,
    ethicalNote
  } = props;

  const renderPopupMainContent = () => {
    if (type === "randomQuestion") {
      return randomQuestions[randomQuestionIndex];
    } else if (type === "loadingScreen") {
      // to optimize with what is coming from the CMS;
      return <div>Please wait, the media is uploading...</div>;
    } else if (type === "abort") {
      return <div>{parse(confirmClosePopupData.text)}</div>;
    } else {
      return <div>{parse(doubleCheckPopupData.text)}</div>;
    }
  };

  const renderEthicalNoteOnConfirm = () => {
    if(type === "confirm"){
      return (
        <div className="ethical_popup">
          {ethicalNote.text}
        </div>
      )
    }
  }


  const renderPopupCTA = () => {
    if (type === "randomQuestion") {
      return (
        <div
          onClick={toggleRandomQuestionsIndex}
          className="new_entry_popup_cta"
        >
          {randomQuestionCTA}
        </div>
      );
    } else if (type === "loadingScreen") {
      return null;
    } else if (type === "abort") {
      let spansToCtas = confirmClosePopupData.ctas.map((ele, index) => {
        if (index === 0) {
          return <span onClick={popupcloseOverlayedSection}>{ele}</span>;
        } else {  
          return <span onClick={entryFormPopupToggle}>{ele}</span>;
        }
      });
      return <div className="entry_form_popup_cta">{spansToCtas}</div>;
    } else {
      // add CTAS here;
      let spansToCtas = doubleCheckPopupData.ctas.map((ele, index) => {
        if (index === 0) {
          return <span onClick={publishTheEntry}>{ele}</span>;
        } else {
          return <span onClick={entryFormPopupToggle}>{ele}</span>;
        }
      });
      return <div className="entry_form_popup_cta">{spansToCtas}</div>;
    }
  };

  return (
    <div className="new_entry_popup_outer">
      <div
        onClick={entryFormPopupToggle}
        className="new_entry_popup_background"
      />
      <div className="new_entry_popup_div">
        <div onClick={entryFormPopupToggle} className="close_cross" />
        <div className="new_entry_popup_text">{renderPopupMainContent()}</div>
        {renderEthicalNoteOnConfirm()}
        {renderPopupCTA()}
      </div>
    </div>
  );
}

export default EntryFormPopups;
