import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import '../App.css';

function Info(props){

  const {
    closeOverlayedSection,
    data: {
      contactText,
      acknowledgmentText,
      email,
      adress,
      tel
    }
  } = props;

    let mailTo = `mailto:${email}`;
    
      return (
        <main 
          className="contact_section">
          <div 
          onClick={closeOverlayedSection}
          className="contact_background">
          </div>
          <section>
            <div className='contact_section_first_div'>
              <p>{acknowledgmentText}</p>
             </div>
             <div className='contact_section_second_div'>
                <p>{contactText}</p>
                <p>
                  <a 
                    target="_blank"
                    rel="noopener noreferrer"
                    href={mailTo}>
                    {email}
                    </a>
                </p>
                <p>{parse(adress)}</p>
                <p>{tel}</p>
             </div>   
          </section>  
          </main>
      );      
};

export default Info;
