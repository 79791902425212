import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import AudioInput from "./AudioInput";
import '../App.css';

function Podcast(props){

    const {
      index,
      displayHoveredPodcastDesc,
      activePodcastIndex,
      handleActivePodcastSelect,
      podcastData : {
        podcast_name,
        podcast_title,
        podcast_link,
        podcast_text,
        podcast_duration
      }
    } = props;

    return (
      <div
        onMouseOver={() => displayHoveredPodcastDesc(index)}
        onMouseLeave={() => displayHoveredPodcastDesc("")}
        className='podcast'>
          <AudioInput 
            index={index}
            activePodcastIndex={activePodcastIndex}
            handleActivePodcastSelect={handleActivePodcastSelect}
            section={"ballados"}
            key={index}
            inputClassName={"podcast_audio_input"}
            audioData={podcast_link}
            audioTitle={podcast_title}
            audioName={podcast_name}
            podcastDuration={podcast_duration}
          />
      </div>
    );
}

export default Podcast;
