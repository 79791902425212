import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import parse from "html-react-parser";
import "../App.css";
import EntryKeywords from "./EntryKeywords";
import EntryMediaUploader from "./EntryMediaUploader";
import EntryFormPopups from "./EntryFormPopups";

function EntryForm(props) {
  const {
    keywordsArrayENFR,
    selectedLanguage,
    closeOverlayedSection,
    showNewFragmentOnMap,
    randomQuestionArray: { randomQuestions, randomQuestionCTA },
    submissionPageData: {
      ethicalNote,
      formLabels,
      header,
      subHeader,
      requirement,
      submissionPageCTAs,
      doubleCheckPopup,
      confirmClosePupup,
      missingInfoPopup,
      loadingPopup,
    },
  } = props;

  const [formData, setFormData] = useState({
    author: "",
    selectedLanguage,
    title: "",
    mainText: "",
    selectedKeywords: [],
    media: "",
    mediaImg: "",
    mediaAudio: "",
    isOnlyText: false,
    date: moment().format("DD/MM/YYYY"),
  });

  const [selectedMediaType, setSelectedMediaType] = useState("");
  const [isOpenPopup, setisOpenPopup] = useState(false);
  const [randomQuestionIndex, setRandomQuestionIndex] = useState(0);
  const [isMissingInfoPopup, setIsMissingInfoPopup] = useState(false);
  const [missingInfoPopupContent, setMissingInfoPopupContent] = useState(null);
  const [isConfirmEntry, setisConfirmEntry] = useState(false);
  const [isConfirmLeave, setIsConfirmLeave] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);


  useEffect(() => {
   if(isConfirmLeave){
   }
  }, [isConfirmLeave]);

  // useEffect(() => {
  //   const { media, mediaImg, mediaAudio } = formData;

  //   if (media.type === undefined) {
  //     return;
  //   } else {
  //     let mediaType = media.type.split("/")[0];
  //     setSelectedMediaType(mediaType);
  //   }

  // }, [formData]);

  useEffect(() => {
    if (isMissingInfoPopup) {
      const myTimeout = setTimeout(closeMissingInfoPopup, 2500);
    }
  }, [isMissingInfoPopup]);

  const closeMissingInfoPopup = () => {
    setIsMissingInfoPopup(false);
    setMissingInfoPopupContent(null);
  };

  const updateSelectedMediaAudio = (event) => {
    setFormData({ ...formData, mediaAudio: event.target.files[0] });
  };

  const deleteSelectedMediaAudio = () => {
    setFormData({ ...formData, mediaAudio: "" });
  };

  const updateSelectedMediaImg = (event) => {
    setFormData({ ...formData, mediaImg: event.target.files[0] });
  };

  const deleteSelectedMediaImg = () => {
    setFormData({ ...formData, mediaImg: "" });
  };

  const handleSelectedKeywords = (index, keywordObj) => {
    const { keywordMain, keywordText } = keywordObj;

    const { selectedKeywords } = formData;

    const isIncludedKeyword = selectedKeywords.some(
      (e) => e.keywordMain === keywordMain
    );

    if (isIncludedKeyword) {
      let newArray = selectedKeywords.filter(
        (ele) => ele.keywordMain !== keywordMain
      );
      setFormData({ ...formData, selectedKeywords: newArray });
    } else {
      let newArray = [...selectedKeywords, keywordObj];
      setFormData({ ...formData, selectedKeywords: newArray });
    }
  };

  const entryFormPopupToggle = () => {
    setIsConfirmLeave(false)
    setisOpenPopup(false);
    setisConfirmEntry(false);
  };


  const popupcloseOverlayedSection = () => {
    setisOpenPopup(false);
    closeOverlayedSection();
  }


  const renderKeywordsArray = () => {
    let keywordsArrayENFRSorted = keywordsArrayENFR
      .sort((a, b) => a[selectedLanguage].localeCompare(b[selectedLanguage]))
      .map((ele, index) => {
        const { main } = ele;

        return (
          <EntryKeywords
            handleSelectedKeywords={handleSelectedKeywords}
            keywordMain={main}
            keywordText={ele[selectedLanguage]}
            index={index}
            selectedKeywords={formData.selectedKeywords}
          />
        );
      });
    return keywordsArrayENFRSorted;
  };


  const toggleConfirmClosePopup = () => {
    // will optimize this;
    const {
      author,
      title,
      mainText,
      selectedKeywords,
      media,
      mediaImg,
      mediaAudio
    } = formData;

    // TODO: Change this to an array
    // Make an addition or use reduce();
    // https://stackoverflow.com/questions/56694086/how-do-i-properly-utilise-reduce-to-count-the-total-number-of-characters-in-a

    if(author.length === 0 &&
      mainText.length === 0 &&
      selectedKeywords.length === 0 && 
      mediaImg.length === 0 &&
      mediaAudio.length === 0 &&
      title.length === 0){
        closeOverlayedSection();
      }else{
        setisOpenPopup(true);
        setIsConfirmLeave(true);
      };    
  };


  const toggleConfirmEntry = () => {
    const {
      author,
      selectedKeywords,
      title,
      mainText,
      mediaAudio,
      mediaImg,
      media,
    } = formData;

    const propertyNames = Object.entries(formData);

    if (author.length && title.length && selectedKeywords.length !== 0) {
      if (mediaAudio.length && mainText.length === 0) {
        setIsMissingInfoPopup(!isMissingInfoPopup);
        setMissingInfoPopupContent(missingInfoPopup[1]);
      } else {
        setisOpenPopup(true);
        setisConfirmEntry(true);
      }
    } else {
      setIsMissingInfoPopup(!isMissingInfoPopup);
      setMissingInfoPopupContent(missingInfoPopup[0]);
    }
  };

  const publishTheEntry = () => {
    setisConfirmEntry(false);
    setIsLoadingScreen(true);

    const { mainText, mediaAudio, mediaImg } = formData;


    // TODO: use switch
    // Assign media to a value for sorting

    if (mainText.length > 0 && mediaAudio === "" && mediaImg === "") {
      setFormData({ ...formData, isOnlyText: true });
    }

    if (mediaAudio !== "" && mediaImg === "") {
      handleUploadCloudinary("audio");
    }
    if (mediaImg !== "" && mediaAudio === "") {
      handleUploadCloudinary("img");
    }
    if (mediaImg !== "" && mediaAudio !== "") {
      handleMultipleUploadsCloudinary();
    }
  };

  const handleUploadCloudinary = (mediaType) => {
    const { mediaAudio, mediaImg } = formData;

    const formDataCloudinary = new FormData();
    formDataCloudinary.append("upload_preset", "u915ugmq");
    formDataCloudinary.append("timestamp", (Date.now() / 1000) | 0);


    // TODO: Change for functional programming

    if (mediaType === "img") {
      formDataCloudinary.append("file", mediaImg);
      axios
        .post(
          "https://api.cloudinary.com/v1_1/sismographie-cloud/image/upload",
          formDataCloudinary
        )
        .then((response) => {
          let url = response.data.secure_url;
          setFormData({ ...formData, mediaImg: url });
        });
    } else if (mediaType === "audio") {
      formDataCloudinary.append("file", mediaAudio);
      axios
        .post(
          "https://api.cloudinary.com/v1_1/sismographie-cloud/upload",
          formDataCloudinary
        )
        .then((response) => {
          let url = response.data.secure_url;
          setFormData({ ...formData, mediaAudio: url });
        });
    }
  };

  const handleMultipleUploadsCloudinary = () => {
    const { mediaAudio, mediaImg } = formData;

    let files = [mediaAudio, mediaImg];
    const formDataCloudinary = new FormData();
    formDataCloudinary.append("upload_preset", "u915ugmq");
    formDataCloudinary.append("timestamp", (Date.now() / 1000) | 0);

    // TODO: Change for functional programming

    return files.map((ele, index) => {
      if (index === 0) {
        formDataCloudinary.append("file", mediaAudio);
        axios
          .post(
            "https://api.cloudinary.com/v1_1/sismographie-cloud/upload",
            formDataCloudinary
          )
          .then((response) => {
            let url = response.data.secure_url;
            setFormData({ ...formData, mediaAudio: url });
          });
      } else {
        formDataCloudinary.append("file", mediaImg);
        axios
          .post(
            "https://api.cloudinary.com/v1_1/sismographie-cloud/image/upload",
            formDataCloudinary
          )
          .then((response) => {
            let url = response.data.secure_url;
            setFormData({ ...formData, mediaImg: url });
          });
      }
    });
  };

  useEffect(() => {
    const { mediaAudio, mediaImg, isOnlyText, date } = formData;

    // see if this works;
    if (typeof mediaImg === "string" && typeof mediaAudio === "string") {
      if (mediaImg.includes("cloudinary")) {
        return sendEntryToServer(formData);
      }
    }

    if (isOnlyText === true) {
      setFormData({ ...formData, media: "Text" });
      return sendEntryToServer(formData);
    }

    // needs to be optimized;
    if (mediaAudio !== "" && mediaImg === "") {
      if (typeof mediaAudio === "string") {
        setFormData({ ...formData, media: "Audio" });
        return sendEntryToServer(formData);
      }
    }

    if (mediaAudio === "" && mediaImg !== "") {
      if (typeof mediaImg === "string") {
        setFormData({ ...formData, media: "Img" });
        return sendEntryToServer(formData);
      }
    }

    if (mediaAudio !== "" && mediaImg !== "") {
      if (typeof mediaImg === "string" || typeof mediaAudio === "string") {
        setFormData({ ...formData, media: "Audio," });
        return uploadOtherFileMultipleUploads(formData);
      }
    }
  }, [formData]);

  const uploadOtherFileMultipleUploads = () => {
    const { mediaAudio, mediaImg } = formData;

    if (typeof mediaImg === "string" && typeof mediaAudio === "object") {
      handleUploadCloudinary("audio");
    }

    if (typeof mediaImg === "object" && typeof mediaAudio === "string") {
      handleUploadCloudinary("img");
    }
  };

  const sendEntryToServer = (formData) => {

    axios
      .post("https://sismographie-back.herokuapp.com/post-entry", {
        data: formData,
      })
      .then((res) => {
        // console.log("Entry sent");
      });
      setIsLoadingScreen(false);
      setisOpenPopup(false);
      closeOverlayedSection();
      showNewFragmentOnMap();
  };

  const renderMissingInfoPopup = () => {
    // data coming from the cms;
    if (isMissingInfoPopup) {
      return (
        <div className="missing_info_popup_main">
          <div className="missing_info_background" />
          <span>{missingInfoPopupContent}</span>
        </div>
      );
    }
  };

  const renderGenerateQuestion = () => {
    if (!isConfirmEntry && isOpenPopup && !isLoadingScreen) {
      return (
        <EntryFormPopups
          type={"randomQuestion"}
          randomQuestionIndex={randomQuestionIndex}
          entryFormPopupToggle={entryFormPopupToggle}
          randomQuestionCTA={randomQuestionCTA}
          randomQuestions={randomQuestions}
          toggleRandomQuestionsIndex={toggleRandomQuestionsIndex}
        />
      );
    }
  };

  const renderLoadingScreen = () => {
    const { mediaAudio, mediaImg } = formData;

    if (!isConfirmEntry && isOpenPopup && isLoadingScreen) {
      return (
        <EntryFormPopups
          type={"loadingScreen"}
          loadingPopupData={loadingPopup}
          mediaAudio={mediaAudio}
          mediaImg={mediaImg}
        />
      );
    }
  };

  const toggleRandomQuestionsIndex = () => {
    const randomQuestionsLength = randomQuestions.length;
    if (randomQuestionIndex < randomQuestionsLength - 1) {
      setRandomQuestionIndex(randomQuestionIndex + 1);
    } else {
      setRandomQuestionIndex(0);
    }
  };

  const renderConfirmEntry = () => {
    if (isConfirmEntry && isOpenPopup && !isLoadingScreen) {
      return (
        <EntryFormPopups
          type={"confirm"}
          // could have done the same props for all;
          doubleCheckPopupData={doubleCheckPopup}
          publishTheEntry={publishTheEntry}
          entryFormPopupToggle={entryFormPopupToggle} 
          ethicalNote={ethicalNote}         
        />
      );
    }
  };

  const renderConfirmLeave = () => {
    if (isConfirmLeave && isOpenPopup && !isLoadingScreen ) {
      return (
        <EntryFormPopups
          type={"abort"}
          confirmClosePopupData={confirmClosePupup}
          entryFormPopupToggle={entryFormPopupToggle}
          popupcloseOverlayedSection={popupcloseOverlayedSection}
        />
      );
    }
  }

  const entrySectionDynamicStyling = () => {
    if (isOpenPopup) {
      return "blur(22px)";
    } else {
      return "blur(0)";
    }
  };

  return (
    <main className="entry_form">
      <div onClick={toggleConfirmClosePopup} className="entry_form_background" />
      <section style={{ filter: entrySectionDynamicStyling() }}>
        <header>
          <div className="entry_header_first">
            <h1>{header}</h1>
            <span>{parse(subHeader)}</span>
            <p>{requirement}</p>
          </div>
          <div className="entry_header_second">
            <div className="entry_form_subheader">
              <form>
                <div>
                  <input
                    onChange={(event) =>
                      setFormData({ ...formData, author: event.target.value })
                    }
                    value={formData.author}
                    id="author"
                    placeholder={formLabels[0]}
                  />
                </div>
                <div>
                  <input
                    onChange={(event) =>
                      setFormData({ ...formData, title: event.target.value })
                    }
                    value={formData.title}
                    id="title"
                    placeholder={formLabels[1]}
                  />
                </div>
              </form>
            </div>
          </div>
        </header>
        <div className="entry_form_inner">
          <div className="entry_form_left_container">
            <form>
              <div>
                <div className="bold flex_textarea">
                  
                  <div>{formLabels[2]}</div>
                  <div>{formLabels[12]}</div>
                
                </div>
                </div>
                <div>
                <textarea
                  onChange={(event) =>
                    setFormData({ ...formData, mainText: event.target.value })
                  }
                  value={formData.mainText}
                  placeholder={formLabels[3]}
                />
              </div>
            </form>
          </div>
          <div className="entry_form_center_container">
            <EntryMediaUploader
              isBold={true}
              mediaType="audio"
              selectedMediaType={selectedMediaType}
              className="entry_form_audio"
              updateSelectedMedia={updateSelectedMediaAudio}
              deleteSelectedMedia={deleteSelectedMediaAudio}
              header={formLabels[7]}
              label={formLabels[11]}
              instructions={formLabels[8]}
              deleteFileCTA={formLabels[10]}
              id="image_uploader"
              name="audio_uploader"
              accept=".mp3, .wav"
              media={formData.mediaAudio}
            />

            <EntryMediaUploader
              isBold={false}
              mediaType="image"
              selectedMediaType={selectedMediaType}
              className="entry_form_img"
              updateSelectedMedia={updateSelectedMediaImg}
              deleteSelectedMedia={deleteSelectedMediaImg}
              header={formLabels[5]}
              label={formLabels[11]}
              instructions={formLabels[6]}
              deleteFileCTA={formLabels[10]}
              id="image_uploader"
              name="image_uploader"
              accept="image/png, image/jpeg"
              media={formData.mediaImg}
            />
          </div>
          <div className="entry_form_right_container">
            <span className="bold">{formLabels[9]}</span>
            <div className="entry_form_right_keywords">
              {renderKeywordsArray()}
            </div>
          </div>
        </div>
        <footer>
          <div className="entry_footer_first">
            <div
              onClick={entryFormPopupToggle}
              className="entry_form_generate_question"
            >
              {parse(submissionPageCTAs[0])}
            </div>
          </div>
          <div className="entry_footer_second">
            <p>{ethicalNote.title}</p>
            <p>{ethicalNote.text}</p>
          </div>
          <div className="entry_footer_third">
            <div className="entry_form_cat_publish">
              <div onClick={toggleConfirmClosePopup}>{submissionPageCTAs[1]}</div>
              <div onClick={toggleConfirmEntry}>{submissionPageCTAs[2]}</div>
            </div>
          </div>
        </footer>
      </section>
      {renderGenerateQuestion()}
      {renderMissingInfoPopup()}
      {renderConfirmEntry()}
      {renderLoadingScreen()}
      {renderConfirmLeave()}
    </main>
  );
}

export default EntryForm;
