const mockData = module.exports = {
    menuArray:  {
      en:
      [
          "ABOUT",
          "FILTER",
          "INDEX",
          "PODCAST",
          "CONTACT",
      ],
      fr:
      [
          "À PROPOS",
          "FILTRER",
          "INDEX",
          "BALADOS",
          "CONTACT",
      ]
    },
    organicShapes : {
      shape1: [
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656595653/svgs_shapes/shapes_1/blob_3_i4f5sc.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656595590/svgs_shapes/shapes_1/blob_2_t0ieak.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656595518/svgs_shapes/shapes_1/blob_wneblw.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656595518/svgs_shapes/shapes_1/blob_1_rix06h.svg"
      ],
      shape2: [
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656602728/svgs_shapes/shapes_2/blob_4_nlnmwo.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656602605/svgs_shapes/shapes_2/blob_3_hrebxr.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656602555/svgs_shapes/shapes_2/blob_2_cxo8b5.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656595758/svgs_shapes/shapes_2/blob_1_rc591h.svg"
      ],
      shape3: [
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656603074/svgs_shapes/shapes_3/blob_wllckr.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656603074/svgs_shapes/shapes_3/blob_1_crev8j.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656603074/svgs_shapes/shapes_3/blob_3_qbodcf.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656603074/svgs_shapes/shapes_3/blob_2_vv7kgq.svg"
      ],
      shape4: [
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656608640/svgs_shapes/shapes_4/blob_euys7q.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656608640/svgs_shapes/shapes_4/blob_2_oekhck.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656608640/svgs_shapes/shapes_4/blob_1_bcqna3.svg",
        "https://res.cloudinary.com/sismographie-cloud/image/upload/v1656608640/svgs_shapes/shapes_4/blob_4_ug4xie.svg"
      ]
    },
    listCategoryArray : {
      en: {
          category: [
            "TITLE",
            "NAME/PSEUDO",
            "CONTENT",
            "DATE",
            "LANGUAGE"
         ],
         types: {
            text: "text", 
            image: "image",
            audio: "audio"
         }
      },
      fr: {
        category: [
            "TITRE",
            "NOM/PSEUDO",
            "CONTENU",
            "DATE",
            "LANGUE"
          ],
          types: {
            text: "text", 
            image: "image",
            audio: "audio"
         }
      },
      sortingKeys: [
        "title",
        "author",
        "mediaType",
        "date",
        "selectedLanguage"
      ]
    },
    addEntry: {
        en: "Share your experience",
        fr: "Partagez votre experience"
    },
    addCTAImg: {
        en: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653082896/ctas/share_your_experience_nayzfl.svg",
        fr: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1646918918/RACONTEZ_VOTRE_EXPE%CC%81RIENCE_-_sans_fond_qgjku6.svg"
    },
    addCTAImgFullBackgroundVersion: {
        en: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653145674/ctas/full_share_your_experience_y7vfcf.svg",
        fr: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653145684/ctas/full_RACONTEZ_VOTRE_EXPE%CC%81RIENCE_-_sans_fond_qgjku6_xbcqfy.svg"
    },
    deleteFiltersCTAImg: {
      en: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653082723/ctas/cancel_filters_erkv3r.svg",
      fr: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653082770/ctas/annuler_les_filtres_yucqhx.svg"
    },
    filtersPage: {
       en: [
          "keywords",
          "Launch search",
          "Delete the selection",
          "Show me a random passage"
       ],
       fr: [
          "mots-clés",
          "Lancer la recherche",
          "Effacer la sélection",
          "Me montrer un fragment aléatoire"
      ] 
    },
    submissionPage: {
        en: {
            header: "SHARE YOUR EXPERIENCE",
            subHeader: "Tell your story in writing or orally and add an image if you like",
            requirement: "fields marked with an asterisk * are required",
            ethicalNote: {
              title: "Ethical commitment",
              text: "The objective of the site is the authentic sharing of the experience of dancers. This is a space for free expression in an atmosphere of kindness and solidarity. Offensive statements about others will not be published." 
            },
            formLabels: [
                "NAME OR PSEUDONYM*",
                "FRAGMENT TITLE*",
                "TEXT*",
                "start writing...",
                "ADD AN IMAGE OR AN AUDIO TESTIMONY",
                "ADD A PICTURE",
                "*The image will be transformed into duotone <br/> jpg or png format",
                "AUDIO TESTIMONY*",
                "mp3 or wav format",
                "PIN KEYWORDS TO YOUR STORY*",
                "Delete File?",
                "Select a file",
                "AND/OR"
            ],
            submissionPageCTAs: [
                "GENERATE A QUESTION FOR INSPIRATION",
                "CANCEL",
                "PUBLISH"
            ],
            doubleCheckPopup: {
                text: "Publish now ? <br/> Your entry will not be editable unless you contact us by email.",
                ctas: ["yes", "no"]
            },
            confirmClosePupup: {
                text: "Do you really want to quit recording your testimony? <br /> Will your data be erased?",
                ctas: ["yes", "no"] 
            },
            missingInfoPopup : [
                "Information missing: please fill the required (*) fields",
                "Information missing: please enter at least a text or an audio"         
            ],
           loadingPopup: [
               "Thanks for your submission. Please wait until your audio is being uploaded",
               "Thanks for your submission. Please wait until your image is being uploaded",
               "Thanks for your submission. Please wait both your audio and image are being uploaded",
           ] 
        },
       fr: {
        header: "racontez votre expérience",
        subHeader: "Racontez votre expérience par écrit ou à l'oral <br /> et ajoutez une image si désiré",
        requirement: "Les champs marqués d’un astérisque sont obligatoires.",
        ethicalNote: {
            title: "Engagement éthique",
            text: "L’objectif du site est le partage authentique de l’expérience des danseuses et danseurs. Ici, la parole est libérée dans une atmosphère de bienveillance et de solidarité. Les propos offensifs à l’égard d’autres personnes seront retirés."
        },
        formLabels: [
            "NOM/PSEUDO*",
            "TITRE DU FRAGMENT*",
            "TÉMOIGNAGE ÉCRIT*",
            "commencez à écrire",
            "AJOUTEZ UNE IMAGE OU UN TÉMOIGNAGE AUDIO",
            "AJOUTEZ UNE IMAGE",
            "L’image sera transformée en bichromie <br/> Format jpg ou png",
            "TÉMOIGNAGE AUDIO*",
            "Format mp3 ou wav",
            "SÉLECTIONNEZ JUSQU’À TROIS MOTS-CLÉS*",
            "Supprimez le fichier?",
            "Choisir un fichier",
            "ET/OU"
        ],
        submissionPageCTAs: [
            "Générez une question <br /> pour stimuler l’inspiration",
            "ANNULEZ",
            "PUBLIEZ"
        ],
        doubleCheckPopup: {
            text: "Publiez maintenant? <br/> Votre entrée ne sera plus modifiable à moins de nous contacter par email",
            ctas: ["oui", "non"]
        },
        confirmClosePupup: {
            text: "Voulez-vous vraiment quitter l’enregistrement de votre témoignage? <br /> Vos données seront effacées? ",
            ctas: ["oui", "non"] 
        },
        missingInfoPopup : [
            "Information manquante: prière de remplir les champs obligatoires (*)",
            "Information manquante: prière d'ajouter au moins un texte ou un audio"         
        ],
        loadingPopup: [
            "Merci pour votre envoi. Veuillez patienter jusqu'à ce que votre audio soit téléversé",
            "Merci pour votre envoi. Veuillez patienter jusqu'à ce que votre image soit téléversée",
            "Merci pour votre envoi. Veuillez patienter jusqu'à ce que votre audio et image soient téléversés",
        ] 
       }
    },  
    languageCTA: {
        short: ["fr", "en"],
        long: ["FRANÇAIS", "ENGLISH"],
    },
    colorsArray: [
        "#B16565",
        "#EAC6F5",
        "#6894AF",
        "#63A78D",
        "#34807A",
        "#7A3F95"
    ],
    colorsMap: {
        audioOnly: "#B16565",
        imgText: "#EAC6F5",
        textOnly: "#6894AF",
        audioImg: "#63A78D",
        all: "#34807A"
    },
    entryAcceptanceSetting: {
        en: {
            text: "Given the sensitive content available on this website, we ask that you read and accept the site’s <a href='https://docs.google.com/document/d/1uN1DOEMCV4h4iLe496KUzo7Le16pYBMBG3EnXVd3hU0/edit' target='_blank'>terms of use</a>. Dancers are responsible for what they publish on Sismography. The site content may not be copied without authorization from Espace Perreault and the contributing artists. Espace Perreault shall not be held liable for inappropriate use of content disseminated on the website.",
            cta: "I have read and accepted the terms of use."
        },
        fr: {
            text: "En regard du contenu sensible diffusé sur ce site, nous vous demandons de lire les <a href='https://docs.google.com/document/d/1uN1DOEMCV4h4iLe496KUzo7Le16pYBMBG3EnXVd3hU0/edit' target='_blank'>conditions d’utilisation</a> du site et de les accepter. Les danseurs et danseuses sont responsables de ce qu’ils et elles publient sur Sismographie. Le contenu du site ne peut être copié sans autorisation d’Espace Perreault et des artistes contributeur·trices. Espace Perreault ne peut être tenu responsable de l’usage inapproprié des contenus diffusés sur le site.",
            cta: "J’ai lu et j’accepte les conditions d’utilisation."
        }
    },
    userAgreementLink: {
        en: {
            text: "Terms of use",
            cta: "https://docs.google.com/document/d/1kKgWlNQu4_NVeHxfC-arYOEdvCGxk8BxhprRi4ABKVw/edit"
        },
        fr: {
            text: "Conditions d’utilisation",
            cta: "https://docs.google.com/document/d/1uN1DOEMCV4h4iLe496KUzo7Le16pYBMBG3EnXVd3hU0/edit"
        }
    },
    resourcesLink: {
        en: {
            text: "Resources",
            cta: "https://docs.google.com/document/d/1csRAm_vwzy8_jc4Ugub9dd5Q2EJNf2qdTU1MnPwOClM/edit"
        },
        fr: {
            text: "Ressources",
            cta: "https://docs.google.com/document/d/1HpxHkPB6xkRM7MjoCCnLKQLOsBn7cCogL_1taaHcUEM/edit"
        }
    },
    cookieSettings: {
        en: {
         cookieMain: "This website uses third-party cookies for media embedding and analytical purposes.",
         cookieCta: [
         "Accept",
         "Learn more"
         ],
         cookiesPrompt: "Use of cookies",
         cookieDetails: "This site does not place permanent cookies on hard drives of visitors’ computers, only a temporary cookie to improve the performance of some of the site’s functionality (language, first visit indicator). In continuing to visit the website, you agree to the use of cookies.",
        },
        fr: {
        cookieMain: "Ce site web utilise des cookies tiers pour l'intégration de médias et à des fins analytiques.",
        cookieCta: [
            "Acceptez",
            "En savoir plus"
         ],
         cookiesPrompt: "Utilisation de fichiers témoins (« cookies »)",
         cookieDetails: "Le présent site ne place aucun fichier témoin (« cookie ») permanent dans les disques durs des ordinateurs des visiteurs, mais uniquement un fichier témoin temporaire requis pour améliorer la performance de certaines fonctionnalités du site (langue, indicateur de première visite). En poursuivant sur le site, vous acceptez l’utilisation des fichiers témoins (“cookies”).",
        }
     },
    randomQuestionArray: {
    en: {
        randomQuestionCTA: "Generate a question for inspiration",
        randomQuestions: [
            "Have you been injured during your dance career? If yes, what were the circumstances?",
            "How has your relationship with your body changed since the injury(ies)?",
            "How has your dancing changed since the injury(ies)?",
            "Are you able to dance professionally with a chronic illness?",
            "What are the body changes that affected your relationship with dance?",
            "Are injuries, disabilities, or chronic pain impediments to you, or are they also opportunities for transformation?",
            "Are there types of injuries that are more difficult to name in the context of dance? If yes, which ones?",
            "How do you reconcile performance and self-care?"
        ]
    },
    fr: {
        randomQuestionCTA: "Generer une nouvelle question",
        randomQuestions: [
        "Avez-vous été blessé·e durant votre carrière en danse? Si oui, dans quelles circonstances?",
        "Comment le rapport à votre corps s'est-il modifié suite à la /aux blessure.s?",
        "Comment votre danse s’est-elle modifiée suite à la/aux blessure.s?",
        "Est-ce possible de danser professionnellement avec une maladie chronique?",
        "Quelles sont les transformations corporelles qui ont affecté votre rapport à la danse?",
        "Est-ce que les blessures, handicaps ou maux chroniques représentent pour vous des empêchements ou bien sont-ils aussi des occasions de transformation?",
        "Y a-t-il des types de blessures qui sont plus difficiles à nommer dans le contexte de la danse ? Si oui, lesquelles?",
        "Comment concilier performance et soin?"
        ]
    }
    },
    headerInfoArray: {
        en: {
            title: "SISMOGRAPHY",
            subtitle: "Recording the injuries, grief, and perils of dance"
        },
        fr: {
            title: "SISMOGRAPHIE",
            subtitle: "Inscrire les blessures, les deuils et les périls de la danse"
        },
    },
    featuredEntriesHeadlinesAnims: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    ],
    contactPage: {
        en: {
            contactText: "We encourage you to write to the following address if you would like more information about Sismography, if you would like to remove your story, or to report a story that does not respect our ethical commitment:",
            acknowledgmentText: "The lands on which Espace Perreault operates is part of the unceded traditional territory of the Kanien’keha:ka (Mohawks), which long served as a place for gathering and sharing among nations.",
            email: "info@espaceperreault.ca",
            adress: "Espace Perreault Choreographic transmission <br/> <a href='https://espaceperreault.ca/fr/' target='_blank'>espaceperreault.ca</a> <br/> 840, rue Cherrier, bureau K-2220  <br/> Montréal / Tiohtià:ke Qc H2L 1H4 <br/>",
            tel: "Tel: 514 906-0988"
        },
        fr : {
            contactText: "Nous vous invitons à nous écrire à l’adresse suivante si vous souhaitez obtenir davantage d’informations à propos de Sismographie, si vous voulez retirer votre témoignage, ou encore signaler un témoignage qui ne respecte pas l’engagement éthique :",
            acknowledgmentText: "Nous tenons à souligner que les terres sur lesquelles l'Espace Perreault œuvre fait partie du territoire traditionnel non cédé des Kanien’keha:ka (Mohawks), qui a longtemps servi de lieu de rassemblement et d’échange entre les nations.",
            email: "info@espaceperreault.ca",
            adress: "Espace Perreault Transmission chorégraphiques <br/> <a href='https://espaceperreault.ca/fr/' target='_blank'>espaceperreault.ca</a> <br/> 840, rue Cherrier, bureau K-2220  <br/> Montréal / Tiohtià:ke Qc H2L 1H4 <br/>",
            tel: "Tél: 514 906-0988"
        },
    },
    mapEntryText: {
        en: {
            leftText: {
                text: "Sismography is a collaborative platform that is a home to the stories of dancers. It is a sensitive archive of the subtle tremors and deep shudders that have marked their practice and that often remain taboo: physical and psychological injuries, illness, conditions, and disabilities that can impede, prevent, transform, and divert dance. It also documents the grief that haunts or liberates it.",
                cta: "Explore the platform"
            },
            rightText: {
                text: "We invite you to share your experience in this open, supportive space. Regardless of your style of dance or how long you have been dancing professionally, your story matters. This is a space where the authenticity of the private experience is what counts. <br/><br/> To honour this commitment, you can sign your name to your story or remain anonymous.",
                cta: "Share your experience"
            },
        },
        fr: {
            leftText: {
              text: "Sismographie est une plateforme collaborative qui accueille des <span class='bold'>récits de danseuses et danseurs</span>. Elle constitue une archive sensible des tremblements discrets ou des secousses profondes qui jalonnent leur pratique et qui restent souvent tabous : <span class='bold'>les blessures physiques ou psychiques, les maladies, les conditions ou les handicaps</span> qui freinent la danse, l’empêchent, la métamorphosent ou la font bifurquer. Elle documente également les deuils qui la hantent ou la libèrent.",
                cta: "Explorez la plateforme"
            },
            rightText: {
                text: "Nous vous invitons à raconter votre expérience dans cet espace ouvert et solidaire. Quels que soient votre style de danse ou la durée de votre pratique professionnelle, votre récit compte. <br/><br/> Ici, la sincérité de l’expérience intime prévaut. Pour respecter cet engagement, vous pouvez signer votre récit ou choisir de garder l’anonymat.",
                cta: "racontez votre expérience"
            },
        }
    },
    projectsAboutText: {
        en: [
            {
                headline: "Why a sensitive archive?",
                text: "Sismography offers dancers a place to express themselves. To create collective knowledge of the dance experience, the platform hosts stories of lived, embodied experience. When dance appears and is offered in the spirit of sharing, what is it concealed at the same time? We should listen to the whispers in the wings: the body that rejoices and that is applauded is sometimes that same body that is worn out, injured, ravaged. Sismography reveals the depth of this paradox that has a place in the lived experience or imagination of dance artists. Inwardly and collectively, part of us wants to agree with the founding myth of no pain, no gain, while another one wants to be freed from it. This tension inhabits our bones, our dreams, our words."
            },
            {
                headline: "A call for caring",
                text: "The phenomenal force of dance has an invisible, secret lining: vulnerability. This requires a faithfully sensitive ear. <br/><br/>  More than a simple collection, the stories will act on each other through resonance and empathy. The result, we hope, will be a more intuitive development of caring and solidarity among artists. In dance, when caring follows pain – which is not always the case – it becomes part of its power."
            },
            {
                headline: "Site content",
                text: "The platform does not document dance pieces through videos, scores, or reviews, but through the intimate experience of artists and their uniquely personal path. <br /><br />  Different means of expression co-exist. The artists share their experience both orally and in writing, in a style and with the number of words they choose, with the freedom to include visuals, such as drawings or photographs, in their contributions. <br /><br />  In the podcast section, you can learn about Danse K par K’s project <span class='italic'>De la glorieuse fragilité, les balados </span> (glorious fragility: the podcasts), which traces the path of artists who have left the stage. At the initiative of choreographer Karine Ledoyen, this series, co-produced by Catherine-Ève Gadoury, is a celebration of dance, underscoring the grief that is inevitably part of it."
            },
        ],
        fr: [
            {
                headline: "Pourquoi cette archive sensible ?",
                text: "Sismographie offre aux danseuses et danseurs un lieu d’expression. Dans le but de constituer un savoir collectif sur l’expérience dansée, la plateforme recueille des témoignages d’expériences vécues, charnelles. Quand la danse apparaît, s’offre en partage, que dissimule-t-elle, simultanément ? Écoutons les bruissements en coulisses : ce corps qui jubile et que l’on applaudit est parfois le même qui s’éreinte, se blesse, voire se ruine. Sismographie révèle la profondeur de ce paradoxe qui occupe une place dans le vécu ou l’imaginaire des artistes de la danse. Intérieurement et collectivement, une part de nous souhaite acquiescer au mythe fondateur de l’inévitable douleur (<span class='italic'>no pain, no gain!</span>), une autre veut s’en affranchir. Cette tension habite nos os, nos rêves, nos discours."
            },
            {
                headline: "L’appel au soin",
                text: "La force prodigieuse de la danse a une doublure invisible, secrète : la vulnérabilité. Celle-ci requiert une écoute sensible, constamment renouvelée. <br/><br/> Au-delà de la simple accumulation, les récits agissent les uns sur les autres par résonance et par empathie. Il en résulte, nous l’espérons, un accroissement plus intuitif du soin et de la solidarité entre les artistes. En danse, lorsque le soin emboîte le pas à la douleur – ce qui n’est pas toujours le cas –, il devient constitutif de sa puissance."
            },
            {
                headline: "Contenu du site",
                text: "Ici, ce n’est pas l’œuvre de danse qui est documentée à l’aide de vidéos, partitions, critiques de journaux, mais bien l’expérience intime des artistes et la singularité de leur cheminement personnel. <br/><br/> Plusieurs moyens d’expression cohabitent. Les artistes témoignent de leur expérience à l’oral ou par écrit, dans un style et selon un nombre de mots de leur choix, puis ont la liberté de joindre à leurs contributions des éléments visuels (dessins, photographies). <br/><br/> Dans la section balados, vous découvrirez le projet De la glorieuse fragilité, les balados, de la compagnie Danse K par K, qui retrace le parcours d’artistes qui ont quitté la scène. À l’initiative de la chorégraphe Karine Ledoyen, cette série, coréalisée avec Catherine-Ève Gadoury, est une véritable célébration de la danse, mise en lumière par les deuils qui la ponctuent irrémédiablement."
            },
        ]
    },

    credits: {
        en: "Sismography is a production of Espace Perreault Choreographic transmission. The podcast section is produced in partnership with Danse K par K. <br/><br/> <span class='bold'>Ideation and coordination: </span> : Catherine Lavoie-Marcus <br/> <span class='bold'>Graphic design</span> : Émilie Allard <br/> <span class='bold'>Web programming</span> : Cyrus Khalatbari <br/> <span class='bold'>Director</span> : Lise Gagnon <br/> <span class='bold'>Assistant to the Director</span>  : Ariane Dessaulles <br/> <span class='bold'>Consulting</span> : Josée Plamondon <br/> <span class='bold'>External reviewers for the Sismography site</span> : Catherine Viau, Yvon Soglo, Corinne Crane, Claire Pearl <br/> <span class='bold'>Ideation and co-production for <span class='italic'>De la glorieuse fragilité, les balados</span> </span>: Karine Ledoyen (Danse K par K) <br/> <span class='bold'>Coréalisation de <span class='italic'>De la glorieuse fragilité, les balados</span></span> : Catherine-Ève Gadoury <br/> <span class='bold'>Copyediting</span> : Romy Snauwaert <br/> <span class='bold'>Translation</span> : Rhonda Mullins <br/><br/>The Sismography site was created with financial support from the Conseil des arts et des lettres du Québec (CALQ), as part of the CALQ’s Exploration and Digital Deployment measure.  <br/><br/>Espace Perreault Choreographic transmission is supported by: <br><ul><li>The Conseil des arts et des lettres du Québec</li><li>The Canada Council for the Arts</li><li>The Conseil des arts de Montréal</li>",


        fr: "Sismographie est une production d’Espace Perreault Transmissions chorégraphiques. La section des balados a été réalisée par Danse K par K. <br/><br/> <span class='bold'>Idéation et coordination</span> : Catherine Lavoie-Marcus <br/> <span class='bold'>Design graphique</span> : Émilie Allard <br/> <span class='bold'>Programmation web</span> : Cyrus Khalatbari <br/> <span class='bold'>Direction</span> : Lise Gagnon <br/> <span class='bold'>Assistance à la direction et édimestre</span>  : Ariane Dessaulles <br/> <span class='bold'>Consultation</span> : Josée Plamondon <br/> <span class='bold'>Regards extérieurs sur le site Sismographie</span> : Catherine Viau, Yvon Soglo, Corinne Crane, Claire Pearl <br/> <span class='bold'>Idéation et coréalisation de De la glorieuse fragilité, les balados</span> : Karine Ledoyen (Danse K par K) <br/> <span class='bold'>Coréalisation de De la glorieuse fragilité, les balados</span> : Catherine-Ève Gadoury <br/> <span class='bold'>Révision</span> : Romy Snauwaert <br/> <span class='bold'>Traduction</span> : Rhonda Mullins <br/><br/>La création du site Sismographie bénéficie du soutien financier du Conseil des arts et des lettres du Québec, dans le cadre de la mesure Exploration et déploiement numérique du Conseil des arts et des lettres du Québec. <br/><br/>Espace Perreault Transmissions chorégraphiques est soutenu par :<br><ul><li>le Conseil des arts et des lettres du Québec</li><li>le Conseil des arts du Canada</li><li>le Conseil des arts de Montréal</li>",
        
    },
    sponsorsLogos: {
        en: [
            "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653144812/commanditaires/3_dr0vn9.png",
            "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653144812/commanditaires/2_g5xbnr.png",
            "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653144812/commanditaires/1_efc9fm.png"
        ],
        fr: [
            "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653144812/commanditaires/3_dr0vn9.png",
            "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653144812/commanditaires/2_g5xbnr.png",
            "https://res.cloudinary.com/sismographie-cloud/image/upload/v1653144812/commanditaires/1_efc9fm.png"
        ]
    },
    keywordsArray: [
      "soins",
      "vieillissement/aging",
      "perception de soi",
      "rapport au milieu",
      "place dans la société",
      "blessure (trauma physique)",
      "blessure (trauma psychologique)",
      "vulnérabilité",
      "réhabilitation",
      "perceptions corporelles",
      "identité/identity",
      "rapports de pouvoir/power relationships",
      "deuil",
      "transformations corporelles",
      "changement de carrière",
      "opportunités",
      "rêves",
      "projections",
      "handicap",
      "Apprentissage",
      "Maux chroniques",
      "Précarité",
      "Rapport au corps",
      "Rapport à l’autorité",
      "Compétition",
      "Rapport à la danse",
      "Performance "
    ],
    keywordsArrayENFR: [
        {
            main: "Soins",
            en: "Care",
            fr: "Soins"
        },
        {
            main: "Vieillissement",
            en: "Aging",
            fr: "Vieillissement"
        },
        {
            main: "Perception de soi",
            en: "Self-image",
            fr: "Perception de soi",
        },
        {
            main: "Rapport au milieu",
            en: "Relationship with the milieu",
            fr: "Rapport au milieu",
        },
        {
            main: "Place dans la société",
            en: "Place in society",
            fr: "Place dans la société",
        },
        {
            main: "Blessure, trauma physique",
            en: "Injury, physical trauma",
            fr: "Blessure, trauma physique",
        },
        {
            main: "Blessure, trauma psychologique",
            en: "Injury, psychological trauma",
            fr: "Blessure, trauma psychologique",
        },
        {
            main: "Vulnérabilité",
            en: "Vulnerability",
            fr: "Vulnérabilité",
        },
        {
            main: "Réhabilitation",
            en: "Rehabilitation",
            fr: "Réhabilitation",
        },
        {
            main: "Perceptions corporelles",
            en: "Body image",
            fr: "Perceptions corporelles",
        },
        {
            main: "Identité",
            en: "Identity",
            fr: "Identité",
        },
        {
            main: "Rapports de pouvoir",
            en: "Power relationships",
            fr: "Rapports de pouvoir",
        },
        {
            main: "Deuil",
            en: "Grief",
            fr: "Deuil",
        },
        {
            main: "Transformations corporelles",
            en: "Body changes",
            fr: "Transformations corporelles",
        },
        {
            main: "Changement de carrière",
            en: "Career change",
            fr: "Changement de carrière",
        },
        {
            main: "Opportunités",
            en: "Opportunities",
            fr: "Opportunités",
        },
        {
            main: "Rêves",
            en: "Dreams",
            fr: "Rêves",
        },
        {
            main: "Projections",
            en: "Projections",
            fr: "Projections",
        },
        {
            main: "Handicap",
            en: "Disability",
            fr: "Handicap",
        },
        {
            main: "Apprentissage",
            en: "Learning",
            fr: "Apprentissage",
        },
        {
            main: "Maux chroniques",
            en: "Chronic pain",
            fr: "Maux chroniques",
        },
        {
            main: "Précarité",
            en: "Precariousness",
            fr: "Précarité",
        },
        {
            main: "Rapport au corps",
            en: "Relationship with the body",
            fr: "Rapport au corps",
        },
        {
            main: "Rapport à l’autorité",
            en: "Relationship with authority",
            fr: "Rapport à l’autorité",
        },
        {
            main: "Compétition",
            en: "Competition",
            fr: "Compétition",
        },
        {
            main: "Rapport à la danse",
            en: "Relationship with dance",
            fr: "Rapport à la danse",
        },
        {
            main: "Performance",
            en: "Performance",
            fr: "Performance",
        },
    ],
    entryTextPopup: {
        en: {
            text: "",
            cta: ""
        },
        fr: {
            text: "",
            cta: ""
        }
    },
    ballados: {
        headline: {
            en: {
                img_credit: "Photo : David Cannon, 2021 / On the photograph : Odile-Amélie Peters",
                podcast_title: "OF GLORIOUS FRAGILITY",
                subpodcast_title: "A production of <a href='https://dansekpark.com/' target='_blank'>Danse K par K</a>  in collaboration with Espace Perreault Transmissions chorégraphiques",
                credits: "Ideation and co-direction of the podcasts: Karine Ledoyen. Co-direction of the podcasts: Catherine-Ève Gadoury",
                img: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1666351528/balados_files/Glorieuse_fragilite%CC%81_r2ixrv.jpg"
            },
            fr: {
                img_credit: "Photo : David Cannon, 2021 / Sur la photo : Odile-Amélie Peters",
                podcast_title: "DE LA GLORIEUSE FRAGILITÉ",
                subpodcast_title: "Une production de <a href='https://dansekpark.com/' target='_blank'>Danse K par K</a> en collaboration avec Espace Perreault Transmissions chorégraphiques",
                credits: "Idéation et co-réalisation des balados : Karine Ledoyen. Co-réalisation des balados : Catherine-Ève Gadoury",
                img: "https://res.cloudinary.com/sismographie-cloud/image/upload/v1666351528/balados_files/Glorieuse_fragilite%CC%81_r2ixrv.jpg"
            },
        },
        mainContent: {
            en: "They have left dance, even though it was their whole life. <br/><br/> They have mourned their art, but how do they deal with the absence and sense of loss? <br/><br/> In these podcasts, choreographer Karine Ledoyen meets artists who had to walk away from dance and celebrates its glorious fragility.",
            fr: "Elles et ils ont quitté la danse, elle était pourtant toute leur vie. <br/><br/> Elles et ils ont fait le deuil de leur art, mais comment apprivoiser l’absence et le manque? <br/><br/> Dans ces balados, la chorégraphe Karine Ledoyen part à la rencontre d’artistes qui ont dû renoncer à la danse et célèbre avec les artistes sa glorieuse fragilité."
        },
        podcasts: {
            en: [
                {
                    podcast_name: "Lucie Boissinot",
                    podcast_title: "Leaving the stage to take care of a child",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Lucie Boissinot (guest), Andrée-Anne Giguère (interview sound), Mathieu Doyon and Patrick Saint-Denis (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Marie Gignac and Olivier Normand (reading). Poem excerpts from the collections <span class='italic'> Chansons transparentes</span>, Jonas Fortier (L’Oie de Cravan, 2019); “Je te lègue,” Dany Boudreault (in <span class='italic'>À nos parents, à nos enfants</span>, ed. Véronique Grondines, Éditions Somme toute, 2017). <br /><br /> “It was a series of unfortunate events. Jean-Pierre Perreault fell seriously ill. Then one of my children got sick. I knew I had to devote myself to my child.” <br /><br /> Lucie Boissinot has worked in contemporary dance for some 30 years. A muse to many choreographers, a sought-after performer and a moving choreographer, she has collaborated on the creation of many Canadian choreographic projects, performing the works of Tedd Robinson and Jean-Pierre Perreault, among others. She is artistic director of the École de danse contemporaine de Montréal.",
                    podcast_duration: "11:30",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367664/mp3/BDLGF_Lucie_Boissinot_Stereo_16bits_48khz_-24LKFS_1_vkc0nd.mp3"

                },
                {
                    podcast_name: "Laura Pinsonneault",
                    podcast_title: "Choosing dance or medicine",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Laura Pinsonneault (guest), Mathieu Doyon and Patrick Saint-Denis (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Alexandrine Warren, Marie Gignac and Olivier Normand (reading). Poem excerpt from the collection <span class='italic'>Moi, figuier sous la neige</span>, Elkahna Talbi (Mémoire d’encrier, 2014). <br /><br /> “One of the reasons I didn’t become a professional dancer is that I didn’t believe in myself enough. I still ask myself… could I have been a dancer? Could I have taken the plunge and succeeded?” <br /><br /> Laura Pinsonneault is a graduate of the École de danse contemporaine de Montréal. She pursued studies in medicine. Today she is a family doctor with Inuit populations.",
                    podcast_duration: "14:32",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367665/mp3/BDLGF_Laura_Pinsonneault_Stereo_16bits_48khz_-24LKFS_1_clozzf.mp3"
                },
                {
                    podcast_name: "Daniel Soulières",
                    podcast_title: " Leaving dance, his body broken",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Daniel Soulières (guest), Andrée-Anne Giguère (interview sound), Mathieu Doyon and Patrick Saint-Denis (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Alexandrine Warren and Olivier Normand (reading). Poem excerpts from the collections <span class='italic'><span class='italic'>La Vie en apnée</span></span>, Philippe Labarre (l’Hexagone, 2017); <span class='italic'>Là où fuit le monde en lumière</span>, Rose Eliceiry (Les Éditions de l’Écrou, 2017). <br /> <br /> “I don’t know whether I will ever dance again. I didn’t like telling choreographers, ‘I can’t do that; I’m in too much pain’.” <br /> <br /> Daniel Soulières spent many years as the one-man-band for Danse-Cité. The professional dancer worked with all the great Quebec choreographers, from Françoise Sullivan to Paul-André Fortier, Édouard Lock, Louise Bédard, and Jean-Pierre Perreault.",
                    podcast_duration: "12:55",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656362894/mp3/BDLGF_Annie-Claude_Geoffroy_Stereo_24bits_48KHZ_-24LKFS_1_iojgnk.mp3"
                },
                {
                    podcast_name: "Catherine Martin",
                    podcast_title: "Quitter la danse, amoureuse",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Catherine Martin (guest), Mathieu Doyon and Patrick Saint-Denis (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Alexandrine Warren and Olivier Normand (reading). Poem excerpts from the collections <span class='italic'>La Vie en apnée</span>, Philippe Labarre (l’Hexagone, 2017); Pistes de danse in <span class='italic'>Les Versets amoureux</span>, Jean-Paul Daoust (éditions Écrits des Forges, Trois-Rivères, 2001). <br /><br /> “It happened because of injury. There was no more joy. I went to a monastery to find myself. And I saw it, love…” <br /><br /> Catherine Martin is a curious, virtuoso performer, who worked with many leading companies early in her career between 1979 and 1990: Desrosiers Dance Theatre, Montanaro Dance, Fondation Jean-Pierre Perreault, L’écran Humain, Linda Rabin and Danse-Partout. She pursued her career in France with Philippe Genty for over six years. She ended her career as a dancer in Quebec City as a teacher and artist with the company Ex Machina.",
                    podcast_duration: "10:39",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367664/mp3/BDJGF_Catherine_Martin_Stereo_16bits_48khz_-24LKFS_1_up7grs.mp3"
                },
                {
                    podcast_name: "Annie-Claude Coutu-Geoffroy",
                    podcast_title: "let down by the practice",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Annie-Claude Coutu-Geoffroy (guest), Mathieu Doyon and Patrick Saint-Denis (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Alexandrine Warren and Olivier Normand (reading). Poem excerpts from the collections <span class='italic'>Tout bas très fort</span>, Geneviève Letarte (Les Écrits des Forges, 2004); <span class='italic'>Parle seul</span> preceded by <span class='italic'>L’obéissance impure</span>, Jean-Simon Desrochers (Les Herbes rouges, 2018); <span class='italic'>Moi qui marche à tâtons dans ma jeunesse noire</span>, Roxanne Desjardins (Les Herbes rouges, 2016); <span class='italic'>Métissée</span>, Ouanessa Younsi (Mémoire d’encrier, 2018). <br /><br />“I stopped dancing because I was tired of giving, giving, giving, without ever receiving enough.”<br /><br />Annie-Claude Coutu-Geoffroy trained at the École supérieure de Danse du Québec and is a graduate of Toronto’s National Ballet School. A versatile performer, she started her career with Les Grands Ballets canadiens and continued in contemporary dance, taking part in projects with Isabelle Van Grimde, Emmanuel Jouthe and Collectif_BBT. In 2008, she made the leap to presentation as dance programmer at the Théâtre Hector-Charland in L’Assomption.",
                    podcast_duration: "13:25",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656362894/mp3/BDLGF_Annie-Claude_Geoffroy_Stereo_24bits_48KHZ_-24LKFS_1_iojgnk.mp3"
                },
                {
                    podcast_name: "Judith Lessard",
                    podcast_title: "Perdre le sens de son art",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Judith Lessard (guest), Andrée-Anne Giguère (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Alexandrine Warren and Olivier Normand (reading). Poem excerpts from the collections <span class='italic'>Tout bas très fort</span>, Geneviève Letarte (Les Écrits des Forges, 2004); <span class='italic'>Ronde de nuit</span>, Laurie Bédard (Le Quartanier, 2016). <br /><br />“I had felt the end coming for a few years. You have to be authentic; otherwise, how can the audience follow you?” <br /><br /> Judith Lessard is a graduate of the École de danse de Québec. Upon graduation, she co-founded Artère, a service organization that trains contemporary dance professionals in Quebec City. She long split her time between stage performance and communications for the Regroupement québécois de la danse. In 2016, she left dance to devote herself to marketing films for the National Film Board of Canada.",
                    podcast_duration: "09:25",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367664/mp3/BDLGF_Judith_Lessard_Stereo_16bits_48khz_-24LKFS_1_lm6yk1.mp3"
                },
            ],
            fr: [
                {
                    podcast_name: "Lucie Boissinot",
                    podcast_title: "Quitter la scène pour prendre soin d’un enfant",
                    podcast_text: "Avec Karine Ledoyen (animation et coréalisation), Catherine-Ève Gadoury (coréalisation et montage), Lucie Boissinot (invitée), Andrée-Anne Giguère (prise de son de l’entrevue), Mathieu Doyon et Patrick Saint-Denis (musique originale), Katya Montaignac (recherchiste), Gabrielle Côté (sélection des poèmes), Marie Gignac et Olivier Normand (lecture). Poèmes extraits des recueils <span class='italic'>Chansons transparentes</span>, Jonas Fortier (L’Oie de Cravan, 2019) ; « Je te lègue », Dany Boudreault (dans <span class='italic'><span class='italic'>À nos parents, à nos enfants</span></span>, dir. Véronique Grondines, Éditions Somme toute, 2017). <br /><br /> « Ça été une juxtaposition d’événements malheureux. Jean-Pierre Perreault est tombé très gravement malade. Après quoi, un de mes enfants a été frappé par la maladie. J’ai alors compris qu’il me faudrait me vouer entièrement à mon enfant. » <br /><br /> Lucie Boissinot œuvre dans le milieu de la danse contemporaine depuis quelque trente ans. Muse de nombreux chorégraphes, interprète recherchée et chorégraphe émouvante, elle collabore à la création d’un grand nombre de projets chorégraphiques canadiens interprétant, entre autres, des œuvres de Tedd Robinson et de Jean-Pierre Perreault. Elle assure aujourd’hui la direction artistique de l’École de danse contemporaine de Montréal.",
                    podcast_duration: "11:30",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367664/mp3/BDLGF_Lucie_Boissinot_Stereo_16bits_48khz_-24LKFS_1_vkc0nd.mp3"

                },
                {
                    podcast_name: "Laura Pinsonneault",
                    podcast_title: "Choisir la danse ou la médecine",
                    podcast_text: "Avec Karine Ledoyen (animation et coréalisation), Catherine-Ève Gadoury (coréalisation et montage), Laura Pinsonneault (invitée), Mathieu Doyon et Patrick Saint-Denis (musique originale), Katya Montaignac (recherchiste), Gabrielle Côté (sélection des poèmes), Alexandrine Warren, Marie Gignac et Olivier Normand(lecture). Poème extrait du recueil <span class='italic'>Moi,figuier sous la neige</span>, Elkahna Talbi (Mémoire d’encrier, 2014). <br /> « Une des raisons pour laquelle je ne suis pas devenue danseuse professionnelle, c’est que je ne croyais pas assez en moi. Mais, je me demande encore… est-ce que j’aurais pu être danseuse, est-ce que j’aurais pu me lancer et réussir ? » <br /> Laura Pinsonneault est diplômée de l’École de danse contemporaine de Montréal. Elle a poursuivi ses études en médecine. Elle pratique aujourd’hui la médecine familiale auprès des populations inuits.",
                    podcast_duration: "14:32",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367665/mp3/BDLGF_Laura_Pinsonneault_Stereo_16bits_48khz_-24LKFS_1_clozzf.mp3"
                },
                {
                    podcast_name: "Daniel Soulières",
                    podcast_title: "Quitter la danse, le corps usé",
                    podcast_text: "Avec Karine Ledoyen (animation et coréalisation), Catherine-Ève Gadoury (coréalisation et montage), Daniel Soulières (invité), Andrée-Anne Giguère (prise de son de l’entrevue), Mathieu Doyon et Patrick Saint-Denis (musique originale), Katya Montaignac (recherchiste), Gabrielle Côté (sélection des poèmes), Alexandrine Warren et Olivier Normand (lecture). Poèmes extraits des recueils <span class='italic'><span class='italic'><span class='italic'>La Vie en apnée</span></span></span>, Philippe Labarre (l’Hexagone, 2017) ; <span class='italic'><span class='italic'>Là où fuit le monde en lumière</span></span>, Rose Eliceiry (Les Éditions de l’Écrou, 2017). <br /> <br /> « Je ne sais pas si je vais redanser un jour. Je n’aimais pas dire aux chorégraphes “je ne peux pas faire ça, mon corps est en souffrance”. » <br /> <br /> Daniel Soulières a longtemps été l’homme-orchestre de Danse-Cité. Danseur professionnel, il a travaillé avec tous les grands chorégraphes québécois, de Françoise Sullivan à Paul-André Fortier, en passant par Édouard Lock, Louise Bédard et Jean-Pierre Perreault.",
                    podcast_duration: "12:55",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1684692575/BDLGF_Daniel_Souliere_Stereo_16bits_48khz_-24LKFS_qkfhd0.mp3"
                },
                {
                    podcast_name: "Catherine Martin",
                    podcast_title: "Leaving dance, in love",
                    podcast_text: "With Karine Ledoyen (host and co-production), Catherine-Ève Gadoury (co-production and editing), Catherine Martin (guest), Mathieu Doyon and Patrick Saint-Denis (original music), Katya Montaignac (researcher), Gabrielle Côté (poem selection), Alexandrine Warren and Olivier Normand (reading). Poem excerpts from the collections <span class='italic'>La Vie en apnée</span>, Philippe Labarre (l’Hexagone, 2017); Pistes de danse in <span class='italic'>Les Versets amoureux</span>, Jean-Paul Daoust (éditions Écrits des Forges, Trois-Rivères, 2001). <br /><br /> “It happened because of injury. There was no more joy. I went to a monastery to find myself. And I saw it, love…” <br /><br /> Catherine Martin is a curious, virtuoso performer, who worked with many leading companies early in her career between 1979 and 1990: Desrosiers Dance Theatre, Montanaro Dance, Fondation Jean-Pierre Perreault, L’écran Humain, Linda Rabin and Danse-Partout. She pursued her career in France with Philippe Genty for over six years. She ended her career as a dancer in Quebec City as a teacher and artist with the company Ex Machina.",
                    podcast_duration: "10:39",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367664/mp3/BDJGF_Catherine_Martin_Stereo_16bits_48khz_-24LKFS_1_up7grs.mp3"
                },
                {
                    podcast_name: "Annie-Claude Coutu-Geoffroy",
                    podcast_title: "Quitter la danse, déçue du métier",
                    podcast_text: "Avec Karine Ledoyen (animation et coréalisation), Catherine-Ève Gadoury (coréalisation et montage), Annie-Claude Coutu-Geoffroy (invitée), Mathieu Doyon et Patrick Saint-Denis (musique originale), Katya Montaignac (recherchiste), Gabrielle Côté (sélection des poèmes), Alexandrine Warren et Olivier Normand (lecture). Poèmes extraits des recueils <span class='italic'><span class='italic'>Tout bas très fort</span></span>, Geneviève Letarte (Les Écrits des Forges, 2004) ; <span class='italic'><span class='italic'>Parle seul</span></span> précédé de <span class='italic'><span class='italic'>L’obéissance impure</span></span>, Jean-Simon Desrochers (Les Herbes rouges, 2018) ; <span class='italic'><span class='italic'>Moi qui marche à tâtons dans ma jeunesse noire</span></span>, Roxanne Desjardins (Les Herbes rouges, 2016) ; <span class='italic'><span class='italic'>Métissée</span></span>, Ouanessa Younsi (Mémoire d’encrier, 2018). <br /><br />« J’ai arrêté la danse parce que j’en avais marre de donner, donner, donner, sans assez recevoir. »<br /><br />Annie-Claude Coutu-Geoffroy a été formée à l’École supérieure de Danse du Québec et est diplômée de l’École nationale de ballet à Toronto. Interprète polyvalente, elle fait ses débuts avec Les Grands Ballets canadiens et poursuit en danse contemporaine, prenant part à divers projets avec Isabelle Van Grimde, Emmanuel Jouthe et le Collectif_BBT. En 2008, elle fait le saut en diffusion comme programmatrice en danse au Théâtre Hector-Charland de L’Assomption.",
                    podcast_duration: "13:25",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656362894/mp3/BDLGF_Annie-Claude_Geoffroy_Stereo_24bits_48KHZ_-24LKFS_1_iojgnk.mp3"
                },
                {
                    podcast_name: "Judith Lessard",
                    podcast_title: "Perdre le sens de son art",
                    podcast_text: "Avec Karine Ledoyen (animation et coréalisation), Catherine-Ève Gadoury (coréalisation et montage), Judith Lessard (invitée), Andrée-Anne Giguère (prise de son de l’entrevue), Mathieu Doyon et Patrick Saint-Denis (musique originale), Katya Montaignac (recherchiste), Gabrielle Côté (sélection des poèmes), Alexandrine Warren et Olivier Normand (lecture). Poèmes extraits des recueils <span class='italic'>Tout bas très fort</span>, Geneviève Letarte (Les Écrits des Forges, 2004) ; <span class='italic'>Ronde de nuit</span>, Laurie Bédard (Le Quartanier, 2016).<br /><br />« Je sentais la fin arriver depuis quelques années. Il faut être sincère, sinon, comment le public peut-il nous suivre en retour ? » <br /><br /> Judith Lessard est diplômée de l’École de danse de Québec. Dès sa sortie de l’école, elle cofonde l’Artère, un organisme de services pour l’entraînement des professionnels en danse contemporaine, à Québec. Longtemps, elle partage son temps entre l’interprétation sur scène et le travail de communication en coulisses pour le Regroupement québécois de la danse. En 2016, elle quitte la danse pour se consacrer à la mise en marché de films au sein de l’Office national du film du Canada.",
                    podcast_duration: "09:25",
                    podcast_link: "https://res.cloudinary.com/sismographie-cloud/video/upload/v1656367664/mp3/BDLGF_Judith_Lessard_Stereo_16bits_48khz_-24LKFS_1_lm6yk1.mp3"
                },
            ]
        }
    }
  };
